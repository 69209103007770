import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9ab8ea20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "confirm-dialog__footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.isVisible,
    title: _ctx.title,
    width: _ctx.width,
    "close-on-click-modal": _ctx.closeOnOutsideClick,
    onOnDialogClose: _cache[0] || (_cache[0] = $event => _ctx.handleDialogClose()),
    style: {
      "padding": "0"
    },
    "data-test": _ctx.dataTest
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_standard_button, {
      outlined: true,
      label: _ctx.cancelButtonText || _ctx.$t('messageBox.cancel'),
      onClick: _ctx.handleCancelAction,
      "data-test": _ctx.dataTest ? `${_ctx.dataTest}-cancel` : 'cancel'
    }, null, 8, ["label", "onClick", "data-test"]), _createVNode(_component_standard_button, {
      label: _ctx.confirmButtonText || _ctx.$t('messageBox.ok'),
      onClick: _ctx.handleConfirmAction
    }, null, 8, ["label", "onClick"])])]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass('confirm-dialog__msg-block' + (_ctx.centered ? ' centered' : ''))
    }, _toDisplayString(_ctx.message), 3)]),
    _: 1
  }, 8, ["visible", "title", "width", "close-on-click-modal", "data-test"]);
}