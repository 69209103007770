import { __decorate } from "tslib";
import axios from "axios";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import WToggle from "@/components/form/w-toggle.vue";
let NotificationParametersTypeNotification = class NotificationParametersTypeNotification extends Vue {
  onChangeToggle(value) {
    if (value) {
      this.activate();
    } else {
      this.desactivate();
    }
  }
  activate() {
    axios.get("/api/v2/notifications/parameters/activate", {
      params: {
        dataType: "json",
        type: this.type
      }
    });
  }
  desactivate() {
    axios.get("/api/v2/notifications/parameters/desactivate", {
      params: {
        dataType: "json",
        type: this.type
      }
    });
  }
};
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "keyLabel", void 0);
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "active", void 0);
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "type", void 0);
NotificationParametersTypeNotification = __decorate([Options({
  components: {
    WToggle
  }
})], NotificationParametersTypeNotification);
export default NotificationParametersTypeNotification;