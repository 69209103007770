import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import WorkflowRejectedBlock from "@/components/workflow/WorkflowRejectedBlock.vue";
let WorkflowGraph = class WorkflowGraph extends Vue {
  mounted() {
    let worflowBlock = this.$refs.workflowInstanceBlock;
    this.setStepsAlign(worflowBlock.scrollWidth, worflowBlock.clientWidth);
  }
  setStepsAlign(scrollWidth, clientWidth) {
    // On modifie le style à la volée pour gérer quand il y a un overflow
    let worflowBlock = this.$refs.workflowInstanceBlock;
    if (scrollWidth > clientWidth) {
      // On ne centre plus le contenu
      worflowBlock.style.justifyContent = "space-between";
    }
  }
  get workflowStep() {
    return this.workflow.rejected ? this.workflow.rejected.stepNumber : this.workflow.currentStep;
  }
};
__decorate([Prop({
  required: true
})], WorkflowGraph.prototype, "workflow", void 0);
WorkflowGraph = __decorate([Options({
  components: {
    WorkflowRejectedBlock
  }
})], WorkflowGraph);
export default WorkflowGraph;