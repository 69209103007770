import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e249a2a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "buttonBar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_standard_button = _resolveComponent("standard-button");
  return _openBlock(), _createElementBlock("div", null, [_ctx.withButton ? (_openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isRecording ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 0,
    outlined: true,
    onAction: _ctx.startRecording
  }, {
    default: _withCtx(() => [_createVNode(_component_icon, {
      icon: "record"
    })]),
    _: 1
  }, 8, ["onAction"])) : _createCommentVNode("", true), _ctx.isRecording ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 1,
    outlined: true,
    onAction: _ctx.stopRecording
  }, {
    default: _withCtx(() => [_createVNode(_component_icon, {
      icon: "stop"
    })]),
    _: 1
  }, 8, ["onAction"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}