import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f64fde86"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "listHeader"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.notificationTitle), 1), _createVNode(_component_w_tool_tip, {
    text: _ctx.settingsLabel
  }, {
    default: _withCtx(() => [_createVNode(_component_icon, {
      onClick: _withModifiers(_ctx.openParameters, ["stop"]),
      color: "dark",
      class: "settingIcon",
      icon: "cog-outline",
      size: "default"
    }, null, 8, ["onClick"])]),
    _: 1
  }, 8, ["text"])]);
}