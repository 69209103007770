import { __decorate } from "tslib";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WTinyMce from "@/components/wrappers/w-tiny-mce.vue";
import { ElMessage } from "element-plus";
import { Prop } from "vue-property-decorator";
let AccessibilityStatementEditVue = class AccessibilityStatementEditVue extends Vue {
  constructor() {
    super(...arguments);
    this.accessibilityData = null;
    this.editorConfig = null;
  }
  mounted() {
    this.getAccessibilityDataAndBuildEditorConfig();
  }
  getAccessibilityDataAndBuildEditorConfig() {
    axios.get('/api/v2/accessibility-data').then(({
      data
    }) => {
      this.accessibilityData = data;
    }).catch(error => {
      var _error$response;
      if (this.isAdmin && ((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 404) {
        this.accessibilityData = {
          statement: this.$t("accessibility.statement.edition.placeholder")
        };
      }
    }).finally(() => {
      if (this.accessibilityData) {
        this.editorConfig = this.buildEditorConfig();
      }
    });
  }
  buildEditorConfig() {
    return {
      language: this.uiLang,
      plugins: 'lists code preview fullscreen link',
      menubar: false,
      style_formats: [{
        title: 'H1',
        format: 'h1'
      }, {
        title: 'H2',
        format: 'h2'
      }, {
        title: 'H3',
        format: 'h3'
      }],
      toolbar: 'undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | lgit ink | link fullscreen',
      height: '70vh'
    };
  }
  saveAccessibilityData(accessibilityStatement) {
    if (accessibilityStatement) {
      var _this$accessibilityDa;
      const data = {
        statement: accessibilityStatement
      };
      (_this$accessibilityDa = this.accessibilityData) !== null && _this$accessibilityDa !== void 0 && _this$accessibilityDa.id ? this.putData(this.accessibilityData.id, data) : this.postData(data);
    }
  }
  postData(data) {
    axios.post('/api/v2/accessibility-data', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      this.onSaveSuccess();
    }, reason => {
      this.onSaveError(reason);
    });
  }
  putData(id, data) {
    axios.put(`/api/v2/accessibility-data/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      this.onSaveSuccess();
    }, reason => {
      this.onSaveError(reason);
    });
  }
  onSaveError(reason) {
    this.displayMessage(this.$t('accessibility.statement.edition.on-save.error'), 'error');
    console.log(reason);
  }
  onSaveSuccess() {
    this.displayMessage(this.$t('accessibility.statement.edition.on-save.success'), 'success');
    this.redirectTo("/accessibility-statement");
  }
  cancelEdit() {
    this.redirectTo("/");
  }
  redirectTo(path) {
    window.location.assign(path);
  }
  displayMessage(msg, type) {
    ElMessage({
      message: msg,
      type: type,
      showClose: false
    });
  }
};
__decorate([Prop({
  default: false
})], AccessibilityStatementEditVue.prototype, "isAdmin", void 0);
__decorate([Prop({
  default: 'en'
})], AccessibilityStatementEditVue.prototype, "uiLang", void 0);
AccessibilityStatementEditVue = __decorate([Options({
  components: {
    WTinyMce,
    StandardButton
  }
})], AccessibilityStatementEditVue);
export default AccessibilityStatementEditVue;