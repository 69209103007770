import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import PdpAgreementScreenForm from "@/components/pdp/PdpAgreementScreenForm.vue";
let PdpAgreementScreen = class PdpAgreementScreen extends Vue {
  userAccept() {
    window.location.assign("/pdp/acceptPdp");
  }
};
PdpAgreementScreen = __decorate([Options({
  components: {
    PdpAgreementScreenForm
  }
})], PdpAgreementScreen);
export default PdpAgreementScreen;