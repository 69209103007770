import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c95db53c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "_genericButtonConteneur",
  style: {
    "white-space": "nowrap"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "buttonContainerStandardButton",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.click && _ctx.click(...args))
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.buttonSubClass, "_genericButton"])
  }, [_createElementVNode("span", {
    class: _normalizeClass(["_labelButton", _ctx.marginsClass])
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2)], 2)])]);
}