import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Events } from "@/events/events";
import { Emit, Prop } from "vue-property-decorator";
import axios from "axios";
import { html } from "@/util/htmlUtils";
let LegacyVideoChapterPlayer = class LegacyVideoChapterPlayer extends Vue {
  constructor() {
    super(...arguments);
    this.loaded = false;
  }
  mounted() {
    this.loadPlayer();
  }
  loadPlayer() {
    axios({
      method: 'get',
      url: this.urlPlayer,
      headers: {
        'Accept': 'text/html',
        'Content-Type': 'text/html'
      }
    }).then(response => {
      const playerContainer = this.$refs['containerPlayer'];
      if (playerContainer) {
        html(playerContainer, response.data);
        const idPlayer = playerContainer.querySelectorAll("video")[0].id;
        // const autoHeightContainer=playerContainer.querySelector(".typeChapitrePlayerConteneur") as HTMLDivElement
        const listAutoHeightContainer = playerContainer.querySelectorAll('[screenresizer=true]');
        setTimeout(async () => {
          for (const container of listAutoHeightContainer) {
            unresizeMeToScreen(container);
          }
          this.loaded = true;
          doResize();
        }, 100);
        // @ts-ignore storing legacy player
        this.legacyVPManager = window['VPManager' + idPlayer];
        // manage autoplay
        if (!this.autoplay) {
          register(Events.VIDEO_PLAYING, this.manageAutoplay);
        }
        register(Events.VIDEO_READY, this.onLegacyPlayerIntilialized);
      }
    }).catch(err => {
      console.error("Error", err);
    });
  }
  // Doc for legacy player
  // https://github.com/mediaelement/mediaelement/blob/master/docs/api.md
  manageAutoplay() {
    this.legacyVPManager.config.player.pause();
    unregister(Events.VIDEO_PLAYING, this.manageAutoplay);
  }
  onLegacyPlayerIntilialized() {
    unregister(Events.VIDEO_READY, this.onLegacyPlayerIntilialized);
    this.legacyVPManager.config.player.addEventListener('play', () => {
      this.onPlay();
    });
    // send the duration
    this.legacyVPManager.config.player.addEventListener('canplay', () => {
      this.onDuration(this.legacyVPManager.config.player.duration);
    });
    this.legacyVPManager.config.player.addEventListener('timeupdate', () => {
      const time = this.legacyVPManager.config.player.currentTime;
      this.onTimeUpdate(time);
    });
    this.legacyVPManager.config.player.addEventListener('seeked', () => {
      const time = this.legacyVPManager.config.player.currentTime;
      this.onSeek(time);
    });
    this.legacyVPManager.config.player.addEventListener('pause', () => {
      this.onPause();
    });
    this.legacyVPManager.config.player.addEventListener('volumechange', () => {
      const volume = this.legacyVPManager.config.player.volume;
      this.onVolumeChange(volume);
    });
  }
  //**************************************
  // EVENT
  //**************************************
  onPlay() {
    return;
  }
  onPause() {
    return;
  }
  onTimeUpdate(time) {
    return;
  }
  onVolumeChange(volume) {
    return;
  }
  onDuration(duration) {
    return;
  }
  onSeek(time) {
    return;
  }
  //******************************
  //* ACTION
  //******************************
  play() {
    this.legacyVPManager.config.player.play();
  }
  pause() {
    this.legacyVPManager.config.player.pause();
  }
  mute() {
    this.legacyVPManager.config.player.setMuted(true);
  }
  unmute() {
    this.legacyVPManager.config.player.setMuted(false);
  }
  setCurrentTime(time) {
    this.legacyVPManager.config.player.setCurrentTime(time);
  }
  setSpeed(speed) {
    this.legacyVPManager.config.player.playbackRate = speed;
  }
  getCurrentTime() {
    return this.legacyVPManager.config.player.currentTime;
  }
};
__decorate([Prop({
  required: true
})], LegacyVideoChapterPlayer.prototype, "urlPlayer", void 0);
__decorate([Prop({
  default: true
})], LegacyVideoChapterPlayer.prototype, "autoplay", void 0);
__decorate([Emit('onPlay')], LegacyVideoChapterPlayer.prototype, "onPlay", null);
__decorate([Emit('onPause')], LegacyVideoChapterPlayer.prototype, "onPause", null);
__decorate([Emit('onTimeUpdate')], LegacyVideoChapterPlayer.prototype, "onTimeUpdate", null);
__decorate([Emit('onVolumeChange')], LegacyVideoChapterPlayer.prototype, "onVolumeChange", null);
__decorate([Emit('onDuration')], LegacyVideoChapterPlayer.prototype, "onDuration", null);
__decorate([Emit('onSeek')], LegacyVideoChapterPlayer.prototype, "onSeek", null);
LegacyVideoChapterPlayer = __decorate([Options({
  components: {}
})], LegacyVideoChapterPlayer);
export default LegacyVideoChapterPlayer;