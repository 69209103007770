import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import Rating from "@/components/rating/Rating.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let DashboardTable = class DashboardTable extends Vue {};
__decorate([Prop({
  required: true
})], DashboardTable.prototype, "params", void 0);
__decorate([Prop({
  required: true
})], DashboardTable.prototype, "dataTable", void 0);
__decorate([Prop({
  default: false
})], DashboardTable.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], DashboardTable.prototype, "loading", void 0);
DashboardTable = __decorate([Options({
  components: {
    EmptyChart,
    Rating,
    WToolTip
  }
})], DashboardTable);
export default DashboardTable;