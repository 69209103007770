import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import Editor from "@tinymce/tinymce-vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { LangEnum } from "@/models/LangEnum";
import { ElMessageBox } from "element-plus/es";
let WTinyMce = class WTinyMce extends Vue {
  constructor() {
    super(...arguments);
    this.saveLabel = '';
    this.cancelLabel = '';
    this.initConfig = null;
    this.initialText = '';
    this.liveText = '';
    this.formatUiLangToTinyMceFormat = lang => {
      switch (lang === null || lang === void 0 ? void 0 : lang.toLowerCase()) {
        case LangEnum.FR:
          return 'fr_FR';
        case LangEnum.EN:
          return 'en';
        case LangEnum.ES:
          return 'es';
        case LangEnum.DE:
          return 'de';
        default:
          return 'en';
      }
    };
  }
  updated() {
    if (this.text && !this.initialText) {
      this.initialText = this.text;
      this.liveText = this.text;
    }
    if (this.customConfig && !this.initConfig) {
      this.initConfig = {
        ...this.customConfig,
        branding: false,
        statusbar: false,
        language: this.formatUiLangToTinyMceFormat(this.customConfig.language)
      };
    }
  }
  onSaveChanges() {
    if (this.initialText === this.liveText) {
      ElMessage({
        message: this.$t('accessibility.statement.edition.nochanges'),
        type: 'info',
        showClose: false
      });
      return;
    }
    this.$emit('onSaveChanges', this.liveText);
  }
  onCancelChanges() {
    ElMessageBox.confirm(this.$t('accessibility.statement.edition.on-cancel.dialog-body'), {
      confirmButtonText: this.$t('accessibility.statement.edition.on-cancel.confirm'),
      cancelButtonText: this.$t('accessibility.statement.edition.on-cancel.abort')
    }).then(() => {
      this.onConfirmCancelChanges();
    });
  }
  onConfirmCancelChanges() {
    this.$emit('onCancelChanges');
  }
};
__decorate([Prop({
  default: ''
})], WTinyMce.prototype, "editorId", void 0);
__decorate([Prop({
  default: null
})], WTinyMce.prototype, "customConfig", void 0);
__decorate([Prop({
  default: ''
})], WTinyMce.prototype, "text", void 0);
__decorate([Prop()], WTinyMce.prototype, "saveLabel", void 0);
__decorate([Prop()], WTinyMce.prototype, "cancelLabel", void 0);
WTinyMce = __decorate([Options({
  components: {
    tinymceEditor: Editor,
    StandardButton
  },
  emits: ["onSaveChanges", "onCancelChanges"]
})], WTinyMce);
export default WTinyMce;