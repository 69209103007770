import { Vue } from "vue-class-component";
import axios from "axios";
export default class AccessibilityStatementVue extends Vue {
  constructor() {
    super(...arguments);
    this.accessibilityData = null;
  }
  mounted() {
    const iframe = document.getElementById("accessibility-statement-iframe");
    if (iframe) {
      axios.get('/api/v2/accessibility-data').then(({
        data
      }) => {
        this.accessibilityData = data;
        if (this.accessibilityData) {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          iframeDoc.body.innerHTML = this.accessibilityData.statement;
          // Adjusting the iframe height onload event
          iframe.style.height = iframeDoc.body.scrollHeight + 16 + 'px';
          // Apply Speach font
          iframeDoc.body.innerHTML += "<style> body { font-family: 'Nunito Sans', sans-serif, Avenir, Helvetica, Arial sans-serif;} </style>";
        }
      });
    }
  }
}