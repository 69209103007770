import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import DashboardBarChart from "@/components/dashboard/charts/DashboardBarChart.vue";
import { Options, Vue } from "vue-class-component";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedBarChart = class AdvancedBarChart extends Vue {};
__decorate([Prop({
  default: ''
})], AdvancedBarChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: 500
})], AdvancedBarChart.prototype, "width", void 0);
__decorate([Prop({
  default: 500
})], AdvancedBarChart.prototype, "height", void 0);
__decorate([Prop({
  required: true
})], AdvancedBarChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "doRotate", void 0);
__decorate([Prop({
  default: ''
})], AdvancedBarChart.prototype, "title", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "loading", void 0);
AdvancedBarChart = __decorate([Options({
  components: {
    EmptyChart,
    DashboardBarChart
  }
})], AdvancedBarChart);
export default AdvancedBarChart;