import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Period } from "@/models/dashboard.model";
import WSelect from "@/components/form/w-select.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import DashboardUsersTiles from "@/components/dashboard/tiles/DashboardUsersTiles.vue";
import ExportCsv from "@/views/exportCsv/ExportCsv.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import { DashboardEnum } from "@/models/DashboardEnum";
import DashboardUsersTab from "@/components/dashboard/tabs/DashboardUsersTab.vue";
import DashboardSpeachesTab from "@/components/dashboard/tabs/DashboardSpeachesTab.vue";
import DashboardSocialTab from "@/components/dashboard/tabs/DashboardSocialTab.vue";
import { isCommentsEnabled } from "@/util/dashboardUtils";
import { Prop } from "vue-property-decorator";
let Dashboard = class Dashboard extends Vue {
  constructor() {
    super(...arguments);
    this.selectedPeriod = Period.THREE_MONTHS;
    this.exportCsv = false;
    this.showSocial = false;
    this.activeRadio = DashboardEnum.SPEACHES;
  }
  mounted() {
    isCommentsEnabled().then(result => this.showSocial = result);
    this.selectedPeriod = this.stringToEnumPeriod(this.period);
  }
  get periodOptions() {
    return [{
      id: Period.ONE_MONTH,
      label: this.$t("admin.dashboard.lastmonth")
    }, {
      id: Period.THREE_MONTHS,
      label: this.$t("admin.dashboard.lastthreemonths")
    }, {
      id: Period.SIX_MONTHS,
      label: this.$t("admin.dashboard.lastsixmonths")
    }, {
      id: Period.TWELVE_MONTHS,
      label: this.$t("admin.dashboard.lasttwelvemonths")
    }, {
      id: Period.TWO_YEARS,
      label: this.$t("admin.dashboard.lasttwoyears")
    }];
  }
  changeSelectedPeriod(newPeriod) {
    this.selectedPeriod = newPeriod;
  }
  stringToEnumPeriod(value) {
    if (Object.values(Period).includes(value)) {
      return value;
    }
    return Period.ONE_MONTH;
  }
};
__decorate([Prop({
  required: false,
  default: null
})], Dashboard.prototype, "period", void 0);
Dashboard = __decorate([Options({
  computed: {
    DashboardEnum() {
      return DashboardEnum;
    }
  },
  components: {
    DashboardSocialTab,
    DashboardSpeachesTab,
    DashboardUsersTab,
    WDialog,
    DashboardUsersTiles,
    StandardButton,
    WSelect,
    ExportCsv
  }
})], Dashboard);
export default Dashboard;