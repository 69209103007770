import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-20b98194"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_big_button = _resolveComponent("big-button");
  const _component_VoiceOver = _resolveComponent("VoiceOver");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.couldVoiceOver ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "voiceOver",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.addVoiceOver && _ctx.addVoiceOver(...args))
  }, [_createVNode(_component_big_button, {
    label: _ctx.$t('switch.synchro.add.voiceover'),
    icon: "microphone"
  }, null, 8, ["label"]), _ctx.startVoiceOver ? (_openBlock(), _createBlock(_component_VoiceOver, {
    key: 0,
    "url-player": _ctx.urlPlayer,
    chapterId: _ctx.chapitreId,
    "generate-voice-enabled": _ctx.couldGenerateVoice,
    onOnClose: _ctx.onCloseVoiceOver,
    onGoToSubtitle: _ctx.goToSubtitles
  }, null, 8, ["url-player", "chapterId", "generate-voice-enabled", "onOnClose", "onGoToSubtitle"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _ctx.couldAddDocument ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "addDocument",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.addDocument && _ctx.addDocument(...args))
  }, [_createVNode(_component_big_button, {
    label: _ctx.$t('switch.synchro.add.document'),
    icon: "file-document-outline"
  }, null, 8, ["label"])])) : _createCommentVNode("", true), _ctx.couldManageSubtitles && _ctx.subtitlesEndpointUrl ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.goToSubtitles && _ctx.goToSubtitles(...args))
  }, [_createVNode(_component_big_button, {
    label: _ctx.$t('go.to.manage.subtitles'),
    icon: "closed-caption-outline"
  }, null, 8, ["label"])])) : _createCommentVNode("", true)]);
}