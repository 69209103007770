import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import SearchCategoryTitle from "@/components/search/SearchCategoryTitle.vue";
import UserCard from "@/components/user/UserCard.vue";
import IconButton from "@/components/buttons/IconButton.vue";
//@ts-ignore
import { Carousel, Slide, Navigation } from 'vue3-carousel';
let UserSearchResult = /** User card */
class UserSearchResult extends Vue {
  constructor() {
    super(...arguments);
    this.currentCount = 0;
  }
  get getBreakpoint() {
    return {
      0: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      607: {
        itemsToShow: 2,
        snapAlign: 'start'
      },
      897: {
        itemsToShow: 3,
        snapAlign: 'start'
      },
      1188: {
        itemsToShow: 4,
        snapAlign: 'start'
      }
    };
  }
  nextPage() {
    //@ts-ignore
    this.$refs.slider.next();
    this.currentCount++;
    if (this.currentCount > this.numberOfItemsLoaded - this.numberOfItemsToLoadInAdvance && this.numberOfItemsLoaded < this.totalNumberOfUsersFound) {
      this.$emit("loadNewPage");
    }
  }
  prevPage() {
    //@ts-ignore
    this.$refs.slider.prev();
    this.currentCount--;
  }
};
__decorate([Prop({
  required: true
})], UserSearchResult.prototype, "usersFound", void 0);
__decorate([Prop({
  required: true
})], UserSearchResult.prototype, "totalNumberOfUsersFound", void 0);
__decorate([Prop({
  required: true
})], UserSearchResult.prototype, "searchTerm", void 0);
__decorate([Prop({
  default: 8
})], UserSearchResult.prototype, "numberOfItemsLoaded", void 0);
__decorate([Prop({
  default: 4
})], UserSearchResult.prototype, "numberOfItemsToLoadInAdvance", void 0);
__decorate([Prop({
  default: false
})], UserSearchResult.prototype, "workflowEnabled", void 0);
UserSearchResult = __decorate([Options({
  components: {
    UserCard,
    SearchCategoryTitle,
    Carousel,
    Slide,
    Navigation,
    IconButton
  }
})
/** User card */], UserSearchResult);
export default UserSearchResult;