import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-369bafd7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "empty_chart"
};
const _hoisted_2 = {
  key: 0,
  class: "empty_chart_text"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "text"
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.loading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('admin.dashboard.emptyTitle')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('admin.dashboard.emptySubtitle')), 1)])) : _createCommentVNode("", true), _createElementVNode("img", {
    src: _ctx.url,
    alt: "Faded blurred chart"
  }, null, 8, _hoisted_5)])), [[_directive_loading, _ctx.loading]]);
}