import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Emit, Prop, Watch } from "vue-property-decorator";
let WDatePicker = class WDatePicker extends Vue {
  constructor() {
    super(...arguments);
    this.cal = this.calendar;
  }
  get dateFormat() {
    let dateFormat = 'M/D/YYYY';
    if (this.$i18n.locale.toLowerCase() === 'de') {
      dateFormat = 'D.M.YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'es') {
      dateFormat = 'D/M/YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'fr') {
      dateFormat = 'DD/MM/YYYY';
    }
    return dateFormat;
  }
  changeCal() {
    this.cal = this.calendar;
  }
  changeCalendar() {
    this.onChangeCalendar(this.cal, this.calendar);
  }
  onChangeCalendar(newDate, oldDate) {
    return;
  }
};
__decorate([Prop({
  default: null
})], WDatePicker.prototype, "calendar", void 0);
__decorate([Prop({
  default: '-'
})], WDatePicker.prototype, "separator", void 0);
__decorate([Prop({
  default: 'bottom'
})], WDatePicker.prototype, "placement", void 0);
__decorate([Watch('calendar')], WDatePicker.prototype, "changeCal", null);
__decorate([Watch('cal')], WDatePicker.prototype, "changeCalendar", null);
__decorate([Emit('changeCalendar')], WDatePicker.prototype, "onChangeCalendar", null);
WDatePicker = __decorate([Options({})], WDatePicker);
export default WDatePicker;