import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import StandardButton from "@/components/buttons/StandardButton.vue";
import GcuDisplay from "@/components/gcu/GcuDisplay.vue";
let GcuAgreementScreenForm = class GcuAgreementScreenForm extends Vue {
  constructor() {
    super(...arguments);
    this.cguEntirelyScrolled = false;
  }
  handleScroll() {
    const gcuContainer = this.$refs.gcuContainer;
    if (gcuContainer.offsetHeight + gcuContainer.scrollTop >= gcuContainer.scrollHeight) {
      this.cguEntirelyScrolled = true;
    }
  }
  clicked() {
    if (this.cguEntirelyScrolled) {
      this.$emit("accept");
    }
  }
};
GcuAgreementScreenForm = __decorate([Options({
  components: {
    GcuDisplay,
    StandardButton
  }
})], GcuAgreementScreenForm);
export default GcuAgreementScreenForm;