import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37a19342"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "_genericButtonConteneur",
  style: {
    "white-space": "nowrap"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loader = _resolveComponent("loader");
  return _openBlock(), _createElementBlock("div", {
    class: "buttonContainerStandardButton",
    onClick: _cache[0] || (_cache[0] = $event => !_ctx.disabled ? _ctx.click() : null)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.buttonSubClass, "_genericButton"])
  }, [_createElementVNode("span", {
    class: _normalizeClass(["_labelButton", _ctx.marginsClass])
  }, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(_toDisplayString(_ctx.label), 1)], true), _ctx.loading ? (_openBlock(), _createBlock(_component_loader, {
    key: 0,
    class: "loaderStandardButton",
    "spinner-size": "spinnersSmall",
    "spinner-color": "lightSpinner"
  })) : _createCommentVNode("", true)], 2)], 2)])]);
}