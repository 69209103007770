import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import IconButton from "@/components/buttons/IconButton.vue";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
import { Events } from "@/events/events";
import { deleteSpeach, goToMonitorSpeach, goToVersioning, goToWorkflowAuthor, gotToWorkflow, refresh } from "@/util/routeUtil";
import { ElMessageBox } from "element-plus";
let MenuSpeachCard = class MenuSpeachCard extends Vue {
  goToMonitorSpeach() {
    goToMonitorSpeach(this.speach);
  }
  goToVersioning() {
    goToVersioning(this.speach);
  }
  dupplicateCour() {
    trig(Events.DUPLICATE_COUR_THUMBNAIL, {
      speachID: this.speach.speachID
    });
  }
  goToWorkflow() {
    if (this.speach.canApprove) {
      gotToWorkflow(this.speach);
    } else {
      goToWorkflowAuthor(this.speach);
    }
  }
  deleteSpeach() {
    ElMessageBox.confirm(this.$t('thumbnail.menu.archiveConfirm'), '', {
      confirmButtonText: this.$t('messageBox.ok'),
      cancelButtonText: this.$t('messageBox.cancel')
    }).then(() => {
      deleteSpeach(this.speach);
      refresh();
    });
  }
};
__decorate([Prop({
  required: true
})], MenuSpeachCard.prototype, "speach", void 0);
MenuSpeachCard = __decorate([Options({
  components: {
    Icon,
    IconButton
  }
})], MenuSpeachCard);
export default MenuSpeachCard;