import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3517ac94"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tile-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_content_tile = _resolveComponent("content-tile");
  const _component_tile = _resolveComponent("tile");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tile, {
    width: 25
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.publishedSpeachs,
      text: _ctx.$t('admin.dashboard.speaches.published'),
      icon: "play-box-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }), _createVNode(_component_tile, {
    width: 25
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.unpublishedSpeachs,
      text: _ctx.$t('admin.dashboard.speaches.unpublished'),
      icon: "play-box-edit-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }), _createVNode(_component_tile, {
    width: 25
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.publishedSpeachs + _ctx.unpublishedSpeachs,
      text: _ctx.$t('admin.dashboard.speaches.total'),
      icon: "play-box-multiple-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }), _createVNode(_component_tile, {
    width: 25
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.viewedSpeachs,
      text: _ctx.$t('admin.dashboard.speaches.viewed'),
      icon: "eye-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  })]);
}