import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3677b33c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tiny-mce-editor-block"
};
const _hoisted_2 = {
  class: "action-buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tinymce_editor = _resolveComponent("tinymce-editor");
  const _component_standard_button = _resolveComponent("standard-button");
  return _ctx.initConfig ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tinymce_editor, {
    id: _ctx.editorId,
    "tinymce-script-src": "/public/javascripts/tinymce/tinymce.min.js",
    init: _ctx.initConfig,
    modelValue: _ctx.liveText,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.liveText = $event)
  }, null, 8, ["id", "init", "modelValue"]), _createElementVNode("div", _hoisted_2, [_ctx.cancelLabel ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 0,
    class: "btn cancel-edit",
    label: _ctx.cancelLabel,
    outlined: true,
    onAction: _ctx.onCancelChanges
  }, null, 8, ["label", "onAction"])) : _createCommentVNode("", true), _ctx.saveLabel ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 1,
    class: "btn save-changes",
    label: _ctx.saveLabel,
    onAction: _ctx.onSaveChanges
  }, null, 8, ["label", "onAction"])) : _createCommentVNode("", true)])])) : _createCommentVNode("", true);
}