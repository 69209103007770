import { __decorate } from "tslib";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
import axios from "axios";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let BaseSpeachCardBookmarkButton = class BaseSpeachCardBookmarkButton extends Vue {
  constructor() {
    super(...arguments);
    this.bookmarkedState = this.speach.isFavori;
  }
  toggleBookmarkState() {
    // optimistic status
    this.bookmarkedState = !this.bookmarkedState;
    return axios.get("/favori.FavoriController/toggleFavori", {
      params: {
        idCour: this.speach.id
      }
    });
  }
};
__decorate([Prop({
  required: true
})], BaseSpeachCardBookmarkButton.prototype, "speach", void 0);
BaseSpeachCardBookmarkButton = __decorate([Options({
  components: {
    WToolTip,
    Icon
  }
})], BaseSpeachCardBookmarkButton);
export default BaseSpeachCardBookmarkButton;