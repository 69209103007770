import { __decorate } from "tslib";
/** Show icon frome this website https://materialdesignicons.com/ */
import { Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
export default class Icon extends Vue {}
__decorate([Prop({
  required: true
})], Icon.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], Icon.prototype, "size", void 0);
__decorate([Prop({
  default: ''
})], Icon.prototype, "color", void 0);