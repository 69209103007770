import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63aaaa48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tile-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_content_tile = _resolveComponent("content-tile");
  const _component_tile = _resolveComponent("tile");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tile, {
    width: _ctx.tileWidth
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.admins,
      text: _ctx.$t('admin.dashboard.administrators.administrator'),
      icon: "shield-account-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }, 8, ["width"]), _ctx.showApprovers ? (_openBlock(), _createBlock(_component_tile, {
    key: 0,
    width: _ctx.tileWidth
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.approvers,
      text: _ctx.$t('admin.dashboard.approvers.approver'),
      icon: "account-check-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }, 8, ["width"])) : _createCommentVNode("", true), _createVNode(_component_tile, {
    width: _ctx.tileWidth
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.speachmakers,
      text: _ctx.$t('admin.dashboard.speachmakers.speachmaker'),
      icon: "account-edit-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }, 8, ["width"]), _createVNode(_component_tile, {
    width: _ctx.tileWidth
  }, {
    default: _withCtx(() => [_createVNode(_component_content_tile, {
      total: _ctx.users,
      text: _ctx.$t('admin.dashboard.users.user'),
      icon: "account-outline"
    }, null, 8, ["total", "text"])]),
    _: 1
  }, 8, ["width"])]);
}