import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let AddCard = class AddCard extends Vue {
  goToUrl() {
    if (!this.outframe) {
      window.location.href = this.url;
    } else {
      alert(this.url);
      open(this.url, '_blank');
    }
  }
};
__decorate([Prop({
  required: true
})], AddCard.prototype, "url", void 0);
__decorate([Prop({
  default: 'plus'
})], AddCard.prototype, "icon", void 0);
__decorate([Prop({
  default: 'click on me'
})], AddCard.prototype, "label", void 0);
__decorate([Prop({
  default: false
})], AddCard.prototype, "outframe", void 0);
AddCard = __decorate([Options({
  components: {
    Icon
  }
})], AddCard);
export default AddCard;