import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-86d4af52"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "alertBody"
};
const _hoisted_2 = {
  key: 0,
  class: "alertIcon"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_el_alert = _resolveComponent("el-alert");
  return _openBlock(), _createBlock(_component_el_alert, {
    class: "wAlert",
    title: _ctx.title,
    description: _ctx.description,
    type: _ctx.type,
    closable: _ctx.closable
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, () => [_createElementVNode("div", _hoisted_1, [_ctx.showIcon ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_icon, {
      icon: _ctx.icon,
      size: "default"
    }, null, 8, ["icon"])])) : _createCommentVNode("", true), _createElementVNode("div", {
      class: "alertDescription",
      innerHTML: _ctx.description
    }, null, 8, _hoisted_3)])], true)]),
    _: 3
  }, 8, ["title", "description", "type", "closable"]);
}