import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
export default class SidePanel extends Vue {}
__decorate([Prop({
  required: true
})], SidePanel.prototype, "visible", void 0);
__decorate([Prop({
  default: false
})], SidePanel.prototype, "closeButtonVisible", void 0);
__decorate([Prop({
  default: false
})], SidePanel.prototype, "wideSidePanel", void 0);