import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { htmliFy } from "@/util/stringUtils";
export default class TextHighlight extends Vue {
  get highlightTerm() {
    if (this.term === "") return this.label;
    let value = this.label;
    let index = 0;
    while (index !== 1) {
      index = value.toLowerCase().indexOf(this.term.toLowerCase(), index);
      if (index !== -1) {
        let capture = value.slice(index, index + this.term.length);
        value = value.slice(0, index) + "<b>" + value.slice(index);
        value = value.slice(0, index + capture.length + 3) + "</b>" + value.slice(index + capture.length + 3);
        index = index + capture.length + 6;
      } else {
        return value;
      }
    }
    // remove xss injection
    return htmliFy(this.label);
  }
}
__decorate([Prop({
  required: true
})], TextHighlight.prototype, "label", void 0);
__decorate([Prop({
  required: true
})], TextHighlight.prototype, "term", void 0);