import { __decorate } from "tslib";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ElMessageBox } from "element-plus";
import BigButton from "@/components/buttons/BigButton.vue";
let EditDocumentChapter = class EditDocumentChapter extends Vue {
  addVideo() {
    ElMessageBox.confirm(this.$t("switch.synchro.warning.video")).then(() => {
      this.handleAddVideo();
    });
  }
  handleAddVideo() {
    axios.get(this.addVideoUrl).then(() => {
      this.reloadPanel();
    });
  }
  addAudio() {
    ElMessageBox.confirm(this.$t("switch.synchro.warning.audio")).then(() => {
      this.handleAddAudio();
    });
  }
  handleAddAudio() {
    axios.get(this.addAudioUrl).then(() => {
      this.reloadPanel();
    });
  }
  reloadPanel() {
    trig("AFFICHER_EDITION_CHAPITRE", this.chapitreId);
    trig("RAFRAICHIR_PLAN_COUR", this.courId);
  }
};
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "addVideoUrl", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "addAudioUrl", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "chapitreId", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "courId", void 0);
EditDocumentChapter = __decorate([Options({
  components: {
    BigButton
  }
})], EditDocumentChapter);
export default EditDocumentChapter;