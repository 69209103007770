import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4675e994"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "iconButton"
};
const _hoisted_2 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon_button = _resolveComponent("icon-button");
  return _ctx.displayAsIcon ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_icon_button, {
    class: "buttonNavigation",
    icon: "restart",
    color: "light2",
    disabled: _ctx.disabled,
    tooltip: _ctx.$t('myAccount.speachSteering.popUpQuizz.reset'),
    onClick: _ctx.resetQuiz
  }, null, 8, ["disabled", "tooltip", "onClick"])])) : (_openBlock(), _createElementBlock("a", {
    key: 1,
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.confirmResetQuiz && _ctx.confirmResetQuiz(...args))
  }, _toDisplayString(_ctx.$t('myAccount.speachSteering.popUpQuizz.reset')), 9, _hoisted_2));
}