import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";
export default class Tile extends Vue {
  get getWidth() {
    return `width: ${this.width}%`;
  }
  get getClass() {
    return 'container' + (this.big ? ' big-tile' : '');
  }
}
__decorate([Prop({
  default: ""
})], Tile.prototype, "title", void 0);
__decorate([Prop({
  default: 33
})], Tile.prototype, "width", void 0);
__decorate([Prop({
  type: Boolean,
  default: false
})], Tile.prototype, "big", void 0);