import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-245aa9f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "searchTitleContainer"
};
const _hoisted_2 = {
  class: "searchLabel"
};
const _hoisted_3 = {
  class: "total"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.categoryTitle)), 1), _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.resultsNumber) + " " + _toDisplayString(_ctx.$t('searchbar.results')), 1), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}