import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cfb10c72"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "row profilePicture"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_text_highlight = _resolveComponent("text-highlight");
  return _openBlock(), _createElementBlock("div", {
    class: "container",
    style: _normalizeStyle(_ctx.cssVars)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    alt: "userImage",
    class: _normalizeClass(["userImage", _ctx.size]),
    src: _ctx.userIsInstanceOfUserInfos ? _ctx.user['imgUrl'] : _ctx.user['img']
  }, null, 10, _hoisted_2)]), _createElementVNode("div", {
    class: "row profileData",
    style: _normalizeStyle(_ctx.cssVars)
  }, [_createVNode(_component_text_highlight, {
    label: _ctx.userIsInstanceOfUserInfos ? _ctx.user['label'] : _ctx.user['firstName'] + ' ' + _ctx.user['lastName'],
    term: _ctx.searchTerm,
    class: "name"
  }, null, 8, ["label", "term"]), _createVNode(_component_text_highlight, {
    label: _ctx.secondLineContent,
    term: _ctx.searchTerm,
    class: "email"
  }, null, 8, ["label", "term"])], 4)], 4);
}