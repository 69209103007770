import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-684bdd04"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "popupContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_notification_parameters = _resolveComponent("notification-parameters");
  const _component_side_panel = _resolveComponent("side-panel");
  const _component_notification_icon = _resolveComponent("notification-icon");
  const _component_notification_header_list = _resolveComponent("notification-header-list");
  const _component_notification_list = _resolveComponent("notification-list");
  const _component_notification_footer_list = _resolveComponent("notification-footer-list");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [_createVNode(_component_side_panel, {
    visible: _ctx.showParameters,
    onOpen: _ctx.openParameters,
    onClose: _ctx.closeParameters,
    onReplaceByAnother: _ctx.closeOnlyParameters
  }, {
    default: _withCtx(() => [_ctx.showParameters ? (_openBlock(), _createBlock(_component_notification_parameters, {
      key: 0
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible", "onOpen", "onClose", "onReplaceByAnother"]), _createVNode(_component_notification_icon, {
    "notification-number": _ctx.unreadNotificationCount,
    onClick: _ctx.togglePanelNotification
  }, null, 8, ["notification-number", "onClick"]), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_1, [_createVNode(_component_notification_header_list, {
      onOpenParameters: _ctx.openParameters
    }, null, 8, ["onOpenParameters"]), _ctx.show ? (_openBlock(), _createBlock(_component_notification_list, {
      key: 0,
      class: "listItemNotification"
    })) : _createCommentVNode("", true), _createVNode(_component_notification_footer_list)], 512), [[_vShow, _ctx.show]])]),
    _: 1
  })]);
}