import axios from "axios";
import { Vue } from "vue-class-component";
export default class GcuDisplay extends Vue {
  constructor() {
    super(...arguments);
    this.gcuContent = "";
  }
  mounted() {
    this.getGcuContent();
  }
  async getGcuContent() {
    const result = await axios.get("/gcu/getGcu", {
      params: {
        dataType: "json"
      }
    });
    this.gcuContent = result.data;
    await this.$nextTick();
    this.$emit("gcuLoaded");
  }
}
/*
    To set some specific style in the content of the GCU in the DataBase use :
     - classe="title"
     - classe="subtitle"
     - classe="text"

    those classes are defined in a non-scoped Stylesheet at the end of this document.
    Non-scoped, because the classes contained in the DB could not be interpreted by a scoped style
     */