import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-98542d58"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "searchIconContainer"
};
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = {
  class: "closeButtonContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      focus: _ctx.hasFocus
    }, "searchFieldContainer"])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_icon, {
    class: "searchIcon",
    icon: "magnify",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.resetSearchTerm()),
    size: "default"
  })]), _withDirectives(_createElementVNode("input", {
    placeholder: _ctx.getPlaceHolder,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.searchTerm = $event),
    type: "text",
    class: "searchInput",
    onKeyup: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.keyUpSearchInput && _ctx.keyUpSearchInput(...args)),
    onFocus: _cache[3] || (_cache[3] = $event => _ctx.hasFocus = true),
    onBlur: _cache[4] || (_cache[4] = $event => _ctx.hasFocus = false)
  }, null, 40, _hoisted_2), [[_vModelText, _ctx.searchTerm]]), _createElementVNode("div", _hoisted_3, [_ctx.searchTerm ? (_openBlock(), _createBlock(_component_icon, {
    key: 0,
    class: "closeButton",
    icon: "close",
    onClick: _cache[5] || (_cache[5] = $event => _ctx.resetSearchTerm()),
    size: "default"
  })) : _createCommentVNode("", true)])], 2);
}