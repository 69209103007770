import { __decorate } from "tslib";
import { Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
export default class Spinner extends Vue {}
__decorate([Prop({
  default: 'spinnersNormal'
})], Spinner.prototype, "spinnerSize", void 0);
__decorate([Prop({
  default: 'darkSpinner'
}) //lightSpinner
], Spinner.prototype, "spinnerColor", void 0);