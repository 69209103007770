import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
let ConfirmDialog = class ConfirmDialog extends Vue {
  constructor() {
    super(...arguments);
    this.isVisible = false;
  }
  mounted() {
    this.isVisible = this.visible;
  }
  onVisiblePropChanges() {
    this.isVisible = this.visible;
  }
  handleDialogClose() {
    return true;
  }
  handleCancelAction() {
    this.isVisible = false;
    return true;
  }
  handleConfirmAction() {
    this.isVisible = false;
    return true;
  }
};
__decorate([Prop({
  default: ''
})], ConfirmDialog.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], ConfirmDialog.prototype, "message", void 0);
__decorate([Prop({
  default: '560px'
})], ConfirmDialog.prototype, "width", void 0);
__decorate([Prop({
  default: false
})], ConfirmDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: true
})], ConfirmDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Prop({
  default: ''
})], ConfirmDialog.prototype, "confirmButtonText", void 0);
__decorate([Prop({
  default: ''
})], ConfirmDialog.prototype, "cancelButtonText", void 0);
__decorate([Prop({
  default: true
})], ConfirmDialog.prototype, "centered", void 0);
__decorate([Prop()], ConfirmDialog.prototype, "dataTest", void 0);
__decorate([Watch('visible')], ConfirmDialog.prototype, "onVisiblePropChanges", null);
__decorate([Emit("on-dialog-close")], ConfirmDialog.prototype, "handleDialogClose", null);
__decorate([Emit("on-canceled")], ConfirmDialog.prototype, "handleCancelAction", null);
__decorate([Emit("on-confirmed")], ConfirmDialog.prototype, "handleConfirmAction", null);
ConfirmDialog = __decorate([Options({
  components: {
    WDialog,
    StandardButton
  },
  emits: ['on-dialog-close', 'on-canceled', 'on-confirmed']
})], ConfirmDialog);
export default ConfirmDialog;