import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e716b2d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "imageLayout ut-imageLayout"
};
const _hoisted_2 = {
  class: "topMenuContent"
};
const _hoisted_3 = {
  class: "topMenuLeft"
};
const _hoisted_4 = {
  class: "topMenuRight"
};
const _hoisted_5 = {
  class: "middleMenuContent"
};
const _hoisted_6 = {
  class: "descriptionLayout",
  "data-id": "387"
};
const _hoisted_7 = {
  class: "titleContainer"
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  key: 1,
  class: "title"
};
const _hoisted_10 = {
  class: "lineContainer"
};
const _hoisted_11 = {
  class: "authorsContainer"
};
const _hoisted_12 = {
  key: 1,
  class: "lastUpdate"
};
const _hoisted_13 = {
  class: "date"
};
const _hoisted_14 = {
  key: 0,
  class: "wfInformation"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_base_speach_card_bookmark_button = _resolveComponent("base-speach-card-bookmark-button");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_author_icon_group = _resolveComponent("author-icon-group");
  const _component_rating = _resolveComponent("rating");
  const _component_icon = _resolveComponent("icon");
  const _component_text_tag = _resolveComponent("text-tag");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'basecardHover': _ctx.hover
    }, "basecard ut-basecard"]),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.goToSpeach())
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle(_ctx.pictureClass),
    class: "imageContent"
  }, [!_ctx.speach.isTemplate ? (_openBlock(), _createBlock(_component_base_speach_card_bookmark_button, {
    key: 0,
    speach: this.speach,
    class: "bookmarkButton"
  }, null, 8, ["speach"])) : _createCommentVNode("", true)], 4), _renderSlot(_ctx.$slots, "portrait", {}, undefined, true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "topLeftSlot", {}, undefined, true)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "topRightSlot", {}, undefined, true)])])]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "middleSlot", {}, undefined, true)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_ctx.isSpeachTitleLengthExceedMaxVisible ? (_openBlock(), _createBlock(_component_w_tool_tip, {
    key: 0,
    text: this.speach.title,
    placement: 'top'
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.truncatedTitle), 1)]),
    _: 1
  }, 8, ["text"])) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(this.speach.title), 1))]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_author_icon_group, {
    author: _ctx.speach.authorsInfos.author,
    "co-editor-groups": _ctx.speach.authorsInfos.coEditorGroups,
    "co-editors": _ctx.speach.authorsInfos.coEditors
  }, null, 8, ["author", "co-editor-groups", "co-editors"])]), _ctx.commentsEnabled === 'true' && !_ctx.speach.isTemplate ? (_openBlock(), _createBlock(_component_rating, {
    key: 0,
    value: _ctx.speach.rating,
    class: "rating"
  }, null, 8, ["value"])) : _createCommentVNode("", true), !_ctx.speach.isTemplate ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_icon, {
    icon: "clock",
    color: "dark",
    size: "small"
  }), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getSpeachDate), 1)])) : _createCommentVNode("", true), _ctx.visibilityString && !_ctx.speach.isTemplate ? (_openBlock(), _createBlock(_component_text_tag, {
    key: 2,
    class: "tag ut-tag"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t(_ctx.visibilityString)), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "descriptionMiddleRight", {}, undefined, true)]), _ctx.speach.isWorkflowInProgress ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_text_tag, {
    type: _ctx.isWFRejected ? 'danger' : 'dark'
  }, {
    default: _withCtx(() => [_createVNode(_component_icon, {
      icon: "sitemap-outline"
    }), _createTextVNode(" " + _toDisplayString(_ctx.getWFDescription), 1)]),
    _: 1
  }, 8, ["type"])])) : _createCommentVNode("", true)])], 2);
}