import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TextTag from "@/components/text/TextTag.vue";
import { VisibilityEnum } from "@/models/visibilityEnum";
import Rating from "@/components/rating/Rating.vue";
import Icon from "@/components/icon/Icon.vue";
import BaseSpeachCardBookmarkButton from "@/components/card/BaseSpeachCardBookmarkButton.vue";
import AuthorIconGroup from "@/components/card/authorIcon/AuthorIconGroup.vue";
import TextHighlight from "@/components/text/TextHighlight.vue";
import { goToSpeach } from "@/util/routeUtil";
import { WorkflowStatus } from "@/models/workflowStatusEnum";
import { DateFilterEnum } from "@/models/DateFilterEnum";
const SPEACH_TITLE_VISIBLE_MAX_SIZE = 60;
let BaseSpeachCard = class BaseSpeachCard extends Vue {
  get visibilityString() {
    if (this.speach.visibility === VisibilityEnum.PUBLIC) {
      return "create.edit.public";
    } else if (this.speach.visibility === VisibilityEnum.PRIVE) {
      return "create.edit.private";
    } else if (this.speach.visibility === VisibilityEnum.SEMI_PRIVE) {
      return "create.edit.semiprivate";
    } else if (this.speach.visibility === VisibilityEnum.RESTREINT) {
      return "create.edit.restreint";
    } else {
      return "";
    }
  }
  get pictureClass() {
    return `background-image: url(${this.speach.img})`;
  }
  get truncatedTitle() {
    return this.speach.title.slice(0, SPEACH_TITLE_VISIBLE_MAX_SIZE) + "...";
  }
  get getSpeachDate() {
    switch (this.speachDateToDisplay) {
      case DateFilterEnum.PUBLICATION_DATE:
        return this.speach.publicationDate;
      default:
        return this.speach.lastUpdatedDate;
    }
  }
  get getWFDescription() {
    if (this.speach.wf_state == WorkflowStatus.AWAITING_VALIDATION) {
      return this.$tc("thumbnail.speach.workflowDescription.awaiting", {
        step: this.speach.wf_step,
        total: this.speach.wf_total_step
      });
    } else {
      return this.$tc("thumbnail.speach.workflowDescription.rejected", {
        step: this.speach.wf_step,
        total: this.speach.wf_total_step
      });
    }
  }
  get isSpeachTitleLengthExceedMaxVisible() {
    return this.speach.title.length > SPEACH_TITLE_VISIBLE_MAX_SIZE;
  }
  goToSpeach() {
    goToSpeach(this.speach, this.outframe, this.loadRegulated);
  }
};
__decorate([Prop({
  required: true
})], BaseSpeachCard.prototype, "speach", void 0);
__decorate([Prop({
  default: false
})], BaseSpeachCard.prototype, "hover", void 0);
__decorate([Prop({
  default: false
})], BaseSpeachCard.prototype, "outframe", void 0);
__decorate([Prop({
  default: false
})], BaseSpeachCard.prototype, "loadRegulated", void 0);
__decorate([Prop({
  default: false
})], BaseSpeachCard.prototype, "isWFRejected", void 0);
__decorate([Prop({
  required: true
})], BaseSpeachCard.prototype, "commentsEnabled", void 0);
__decorate([Prop({
  default: DateFilterEnum.LAST_MAJ
})], BaseSpeachCard.prototype, "speachDateToDisplay", void 0);
BaseSpeachCard = __decorate([Options({
  components: {
    TextHighlight,
    AuthorIconGroup,
    BaseSpeachCardBookmarkButton,
    Icon,
    Rating,
    TextTag
  }
})], BaseSpeachCard);
export default BaseSpeachCard;