import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { Events } from "@/events/events";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import { RecorderType } from "@/models/recorderType.model";
let ConvertQuizToExamLink = class ConvertQuizToExamLink extends Vue {
  constructor() {
    super(...arguments);
    this.isConfirmDialogVisible = false;
  }
  convertQuizzToExamQuizz() {
    axios.get(`/api/v2/quizz/do-convert-to-quizz-exam`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        chapterId: this.chapterId
      }
    }).then(() => {
      this.toggleConfirmDialogToFalse();
      this.refreshChapterView();
    }, () => WElMessage.showErrorMessage(this.$t('errors.default')));
  }
  toggleConfirmDialogToFalse() {
    this.isConfirmDialogVisible = false;
  }
  refreshChapterView() {
    trig(Events.AFFICHER_EDITION_CHAPITRE, {
      id: this.chapterId,
      type: RecorderType.QUIZZ_EXAMEN
    });
  }
};
__decorate([Prop({
  required: true
})], ConvertQuizToExamLink.prototype, "chapterId", void 0);
ConvertQuizToExamLink = __decorate([Options({
  components: {
    ConfirmDialog
  }
})], ConvertQuizToExamLink);
export default ConvertQuizToExamLink;