import { __decorate } from "tslib";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let AuthorIconGroupPicto = class AuthorIconGroupPicto extends Vue {
  get type() {
    if (this.user.type) {
      return this.user.type;
    } else {
      return 'author';
    }
  }
  get label() {
    if (this.user.label) {
      return this.user.label;
    } else {
      return this.user.firstName + " " + this.user.lastName;
    }
  }
  get myStyle() {
    return {
      "min-width": `${this.size}px`,
      width: `${this.size}px`,
      "max-width": `${this.size}px`,
      "min-height": `${this.size}px`,
      height: `${this.size}px`,
      "border-radius": `${this.size / 3}px`,
      "max-height": `${this.size}px`,
      "background-image": this.image
    };
  }
  get image() {
    if (this.user.imgUrl) {
      return `url('${this.user.imgUrl}')`;
    } else if (this.user.img) {
      return `url('${this.user.img}')`;
    } else {
      return 'none';
    }
  }
};
__decorate([Prop({
  required: true
})], AuthorIconGroupPicto.prototype, "user", void 0);
__decorate([Prop({
  default: 24
})], AuthorIconGroupPicto.prototype, "size", void 0);
AuthorIconGroupPicto = __decorate([Options({
  components: {
    WToolTip,
    Icon
  }
})], AuthorIconGroupPicto);
export default AuthorIconGroupPicto;