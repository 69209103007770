import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-529d2952"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "iconContainer"
};
const _hoisted_2 = {
  class: "labelLine"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", {
    class: "addCardContainer",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.goToUrl && _ctx.goToUrl(...args))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_icon, {
    icon: _ctx.icon,
    label: _ctx.label
  }, null, 8, ["icon", "label"])]), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1)]);
}