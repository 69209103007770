import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
import { Options, Vue } from "vue-class-component";
let Rating = class Rating extends Vue {
  setNote(note) {
    if (this.editable) {
      this.$emit("input", note);
    }
  }
};
__decorate([Prop({
  default: false
})], Rating.prototype, "editable", void 0);
__decorate([Prop({
  default: 0
})], Rating.prototype, "value", void 0);
Rating = __decorate([Options({
  components: {
    Icon
  }
})], Rating);
export default Rating;