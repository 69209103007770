import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let BigButton = class BigButton extends Vue {};
__decorate([Prop({
  default: ""
})], BigButton.prototype, "className", void 0);
__decorate([Prop({
  default: ""
})], BigButton.prototype, "icon", void 0);
__decorate([Prop({
  default: ""
})], BigButton.prototype, "label", void 0);
BigButton = __decorate([Options({
  components: {
    Icon
  }
})], BigButton);
export default BigButton;