import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let WAlert = class WAlert extends Vue {
  get icon() {
    if (this.type === 'info') {
      return 'information-outline';
    } else if (this.type === 'warning') {
      return 'alert-circle-outline';
    } else if (this.type === 'error') {
      return 'close-octagon-outline';
    }
    return 'check-circle-outline';
  }
};
__decorate([Prop({
  default: ''
})], WAlert.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], WAlert.prototype, "description", void 0);
__decorate([Prop({
  default: 'info'
}) // values: success, info, warning, error
], WAlert.prototype, "type", void 0);
__decorate([Prop({
  default: false
})], WAlert.prototype, "showIcon", void 0);
__decorate([Prop({
  default: true
})], WAlert.prototype, "closable", void 0);
WAlert = __decorate([Options({
  components: {
    Icon
  }
})], WAlert);
export default WAlert;