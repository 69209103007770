import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1356ed43"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "groupIconContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", null, [_ctx.type === 'author' ? (_openBlock(), _createBlock(_component_w_tool_tip, {
    key: 0,
    text: _ctx.label,
    width: 100
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      style: _normalizeStyle(_ctx.myStyle),
      class: "authorIcon"
    }, null, 4)]),
    _: 1
  }, 8, ["text"])) : _createCommentVNode("", true), _ctx.type === 'group' ? (_openBlock(), _createBlock(_component_w_tool_tip, {
    key: 1,
    text: _ctx.label,
    width: 100
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      style: _normalizeStyle(_ctx.myStyle),
      class: "groupIcon bg_dark"
    }, [_createVNode(_component_icon, {
      icon: "account-multiple-outline",
      size: "small",
      color: "white"
    })], 4)])]),
    _: 1
  }, 8, ["text"])) : _createCommentVNode("", true)]);
}