import { __decorate } from "tslib";
import { Prop } from 'vue-property-decorator';
import { Vue } from "vue-class-component";
export default class WSelect extends Vue {
  constructor() {
    super(...arguments);
    this.realValue = this.value;
  }
  onChange(value) {
    //  this.$emit('change',value);
  }
}
__decorate([Prop({
  default: ''
})], WSelect.prototype, "value", void 0);