import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Emit, Prop, Watch } from "vue-property-decorator";
let WDatePickerRange = class WDatePickerRange extends Vue {
  constructor() {
    super(...arguments);
    this.calRange = this.calendarRange;
  }
  get defaultRanges() {
    return [{
      text: this.$t('datePicker.thisWeek'),
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan7days'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan30days'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan3months'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      }
    }];
  }
  get dateFormat() {
    let dateFormat = 'M/D/YYYY';
    if (this.$i18n.locale.toLowerCase() === 'de') {
      dateFormat = 'D.M.YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'es') {
      dateFormat = 'D/M/YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'fr') {
      dateFormat = 'DD/MM/YYYY';
    }
    return dateFormat;
  }
  changeCalRange() {
    this.calRange = this.calendarRange;
  }
  changeCalendarRange() {
    this.onChangeCalendarRange(this.calRange);
  }
  onChangeCalendarRange(dates) {
    return;
  }
};
__decorate([Prop({
  default: null
})], WDatePickerRange.prototype, "calendarRange", void 0);
__decorate([Prop({
  default: '-'
})], WDatePickerRange.prototype, "separator", void 0);
__decorate([Prop({
  default: 'bottom'
})], WDatePickerRange.prototype, "placement", void 0);
__decorate([Watch('calendarRange')], WDatePickerRange.prototype, "changeCalRange", null);
__decorate([Watch('calRange')], WDatePickerRange.prototype, "changeCalendarRange", null);
__decorate([Emit('changeCalendarRange')], WDatePickerRange.prototype, "onChangeCalendarRange", null);
WDatePickerRange = __decorate([Options({})], WDatePickerRange);
export default WDatePickerRange;