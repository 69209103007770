import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e5114e9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "containerLoading"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TitlePopUp = _resolveComponent("TitlePopUp");
  const _component_loader = _resolveComponent("loader");
  const _component_StandardButton = _resolveComponent("StandardButton");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    width: '400px',
    visible: _ctx.visible,
    onOnDialogClose: _ctx.closePopUp,
    modal: true,
    "close-on-outside-click": false,
    "append-to-body": true
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_TitlePopUp, {
      class: "titlePopUp"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('audioTrackLoader.title')), 1)]),
      _: 1
    }), _createVNode(_component_loader, {
      class: "loader"
    }), _createVNode(_component_StandardButton, {
      class: "buttonCancelAudio",
      label: _ctx.$t('audioTrackLoader.buttonCancel'),
      outlined: true,
      onClick: _ctx.cancelGeneration
    }, null, 8, ["label", "onClick"])])]),
    _: 1
  }, 8, ["visible", "onOnDialogClose"]);
}