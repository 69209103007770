import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { formatDate } from "@/util/stringUtils";
export default class WorkflowRejectedBlock extends Vue {
  get dateFormatted() {
    return formatDate(this.date);
  }
}
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "comment", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "groupName", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "date", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "stepNumber", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "stepsCount", void 0);