import { __decorate } from "tslib";
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Vue } from "vue-class-component";
export default class WSelect extends Vue {
  constructor() {
    super(...arguments);
    this.realValue = '';
  }
  mounted() {
    this.realValue = this.value;
  }
  onBlur() {
    this.$emit('blur', this.realValue);
  }
  onChangeValue() {
    this.$emit('change', this.realValue);
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
}
__decorate([Prop({
  default: 'default'
})], WSelect.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WSelect.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WSelect.prototype, "placeholder", void 0);
__decorate([Prop({
  default: ''
})], WSelect.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WSelect.prototype, "loading", void 0);
__decorate([Prop({
  default: "value"
})], WSelect.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "label"
})], WSelect.prototype, "labelKey", void 0);
__decorate([Prop()], WSelect.prototype, "dataTest", void 0);
__decorate([Prop({
  default: []
})], WSelect.prototype, "options", void 0);
__decorate([Emit('blur')], WSelect.prototype, "onBlur", null);
__decorate([Emit('change')], WSelect.prototype, "onChangeValue", null);
__decorate([Watch('value')], WSelect.prototype, "onChangePropValue", null);