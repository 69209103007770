import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-015bb626"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_advanced_line_chart = _resolveComponent("advanced-line-chart");
  const _component_tile = _resolveComponent("tile");
  const _component_dashboard_table = _resolveComponent("dashboard-table");
  const _directive_observe_visibility = _resolveDirective("observe-visibility");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.social.interact.title'),
    width: 100,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_line_chart, {
      graphs: _ctx.socialInteract,
      height: 350,
      title: _ctx.$t('admin.dashboard.social.interact.title'),
      width: 1060,
      "legend-tooltip-id": "socialInteractLegend",
      big: true,
      loading: _ctx.socialInteractLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedSocialInteract]]), _createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.table.title.topSpeachByScore'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_dashboard_table, {
      dataTable: _ctx.topSpeachByRating,
      params: _ctx.topSpeachByRatingParams,
      loading: _ctx.topSpeachRatingLoading
    }, null, 8, ["dataTable", "params", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedTopSpeachRating]]), _withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.table.title.topSpeachMakerByScore'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_dashboard_table, {
      dataTable: _ctx.topSpeachmakerByRating,
      params: _ctx.topSpeachmakerByRatingParams,
      loading: _ctx.topSpeachMakerRatingLoading
    }, null, 8, ["dataTable", "params", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedTopSpeachMakerRating]])])], 64);
}