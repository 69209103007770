
export const html=(element:HTMLDivElement,html:string):void=>{
  element.innerHTML=html;
  const scripts = element.querySelectorAll('script');
  scripts.forEach((oldScript) => {
    const newScript = document.createElement('script');
    newScript.text = oldScript.innerHTML;
    oldScript.parentNode?.replaceChild(newScript, oldScript);
  });
}
