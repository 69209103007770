import { __decorate } from "tslib";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BigButton from "@/components/buttons/BigButton.vue";
import { ElMessageBox } from "element-plus";
import { RecorderType } from "@/models/recorderType.model";
import VoiceOver from "@/components/video/VoiceOver.vue";
import { Events } from "@/events/events";
let EditVideoChapter = class EditVideoChapter extends Vue {
  constructor() {
    super(...arguments);
    this.startVoiceOver = false;
  }
  onCloseVoiceOver() {
    this.startVoiceOver = false;
    // trig(Events.AFFICHER_EDITION_CHAPITRE, {id: this.chapitreId})
    this.reloadPanel(undefined);
  }
  addDocument(event) {
    ElMessageBox.confirm(this.$t("switch.synchro.warning.document")).then(this.handleAddDocument);
  }
  handleAddDocument() {
    axios.get(this.addPresentationUrl).then(() => {
      this.reloadPanel(RecorderType.WC_PPT);
    });
  }
  reloadPanel(type = undefined) {
    trig(Events.AFFICHER_EDITION_CHAPITRE, {
      id: this.chapitreId,
      type: type
    });
  }
  addVoiceOver() {
    this.startVoiceOver = true;
  }
  async goToSubtitles() {
    if (this.subtitlesEndpointUrl) {
      let response = await axios.get("/api/transcript/token", {
        params: {
          chapterId: this.chapitreId
        }
      });
      window.location.assign(`${this.subtitlesEndpointUrl}?token=${response.data.token}&lng=${response.data.lng}`);
      return response.data;
    }
  }
};
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "addPresentationUrl", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "chapitreId", void 0);
__decorate([Prop()], EditVideoChapter.prototype, "subtitlesEndpointUrl", void 0);
__decorate([Prop({
  default: true
})], EditVideoChapter.prototype, "couldAddDocument", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "couldManageSubtitles", void 0);
__decorate([Prop({
  default: true
})], EditVideoChapter.prototype, "couldVoiceOver", void 0);
__decorate([Prop({
  default: false
})], EditVideoChapter.prototype, "couldGenerateVoice", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "urlPlayer", void 0);
EditVideoChapter = __decorate([Options({
  components: {
    VoiceOver,
    BigButton
  }
})], EditVideoChapter);
export default EditVideoChapter;