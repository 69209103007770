import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a1cce852"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tag-point"
};
const _hoisted_2 = {
  class: "tag-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.type, "tag-container"])
  }, [_ctx.point ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2);
}