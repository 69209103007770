import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import axios from "axios";
import { debounce } from "lodash";
import { Prop, Watch } from "vue-property-decorator";
export default class UserSearchCompact extends Vue {
  constructor() {
    super(...arguments);
    this.searchTerm = '';
    this.users = [];
    this.totalNbOfUsers = 0;
    this.page = 1;
    this.count = 10;
    this.selectedUsers = [];
    this.loading = false;
    this.noMore = true;
    this.throttledLoadMoreUsers = debounce(this.loadMoreUsers, 500);
  }
  mounted() {
    this.updateSelectedUsers();
  }
  updateSelectedUsers() {
    this.selectedUsers = this.alreadySelectedUsers;
  }
  get infiniteScrollDisabled() {
    return this.loading || this.noMore;
  }
  searchUsers(query) {
    this.page = 1;
    this.users = [];
    this.noMore = false;
    if (query) {
      this.searchTerm = query;
      this.loadMoreUsers();
    } else {
      this.searchTerm = '';
    }
  }
  loadMoreUsers() {
    if (!this.infiniteScrollDisabled) {
      this.loading = true;
      axios.get("/api/v2/users", {
        params: {
          dataType: "json",
          term: this.searchTerm,
          count: this.count,
          // page: this.users.page
          page: this.page
        }
      }).then(response => {
        const newUsers = response.data.results;
        newUsers.forEach(user => this.users.push(user));
        this.page++;
        this.totalNbOfUsers = response.data.totalNumberOfUsersFound;
        if (this.users.length >= response.data.totalNumberOfUsersFound) {
          this.noMore = true;
        }
      }, error => {
        console.error('Error while loading users list: ' + error);
        this.noMore = true;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
  pushChangedValues(event) {
    this.$emit('selectedUsers', this.selectedUsers);
  }
}
__decorate([Prop({
  default: []
})], UserSearchCompact.prototype, "alreadySelectedUsers", void 0);
__decorate([Watch('alreadySelectedUsers', {
  immediate: true
})], UserSearchCompact.prototype, "updateSelectedUsers", null);