import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import Icon from "@/components/icon/Icon.vue";
let ContentTile = class ContentTile extends Vue {};
__decorate([Prop({
  required: true
})], ContentTile.prototype, "text", void 0);
__decorate([Prop({
  required: true
})], ContentTile.prototype, "total", void 0);
__decorate([Prop({
  required: true
})], ContentTile.prototype, "icon", void 0);
ContentTile = __decorate([Options({
  components: {
    Icon
  }
})], ContentTile);
export default ContentTile;