import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import WSelect from "@/components/form/w-select.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WToggle from "@/components/form/w-toggle.vue";
import { Prop } from "vue-property-decorator";
import delay from "delay";
import axios from "axios";
import Icon from "@/components/icon/Icon.vue";
let ScormExportDialog = class ScormExportDialog extends Vue {
  constructor() {
    super(...arguments);
    this.url = '';
    this.scormTypeSelected = "SCORM_LITE";
    this.scormNavigationInContent = false;
    this.scormScheduledVersion = false;
    this.waitingToDownload = "";
    this.isDownloadInProgress = false;
  }
  get scormTypes() {
    let types = [{
      value: "SCORM_LITE",
      label: this.$t('admin.dashboard.speaches.sidebar.scormExport.scormEmbed')
    }];
    if (this.scormClassicEnabled) {
      types.push({
        value: "SCORM_FULL",
        label: this.$t('admin.dashboard.speaches.sidebar.scormExport.scormClassic')
      });
    }
    return types;
  }
  async startDownload() {
    if (this.speach && this.speach.speachID) {
      this.isDownloadInProgress = true;
      await this.launchJobScorm(this.speach);
      let isFileCreated = false;
      while (!isFileCreated) {
        await delay(2000);
        isFileCreated = await this.checkIfFileCreated();
      }
      this.downloadFileUrl().then(url => {
        this.url = url;
      });
    }
  }
  async download() {
    open(this.url, "_blank");
    this.closePopup();
  }
  async launchJobScorm(speach) {
    const result = await axios.get('/administration/scorm/exportScorm', {
      params: {
        id: speach.id,
        contentNavigation: this.scormNavigationInContent,
        contentDelayed: this.scormScheduledVersion,
        type: this.scormTypeSelected
      }
    });
    this.waitingToDownload = result.data.dataValue;
  }
  async checkIfFileCreated() {
    const result = await axios.get('/administration/scorm/checkScormCreated', {
      params: {
        name: this.waitingToDownload
      }
    });
    return result.data;
  }
  async downloadFileUrl() {
    return '/administration/scorm/download/' + this.waitingToDownload;
  }
  closePopup() {
    this.isDownloadInProgress = false;
    this.$emit('closePopup');
  }
};
__decorate([Prop({
  required: true
})], ScormExportDialog.prototype, "speach", void 0);
__decorate([Prop({
  default: false
})], ScormExportDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: false
})], ScormExportDialog.prototype, "scormClassicEnabled", void 0);
ScormExportDialog = __decorate([Options({
  components: {
    Icon,
    WToggle,
    WDialog,
    StandardButton,
    WSelect
  },
  emits: ['closePopup']
})], ScormExportDialog);
export default ScormExportDialog;