import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
let AlertDialog = class AlertDialog extends Vue {
  constructor() {
    super(...arguments);
    this.isVisible = false;
  }
  mounted() {
    this.isVisible = this.visible;
  }
  onVisiblePropChanges() {
    this.isVisible = this.visible;
  }
  handleDialogClose() {
    this.isVisible = false;
    return true;
  }
};
__decorate([Prop({
  default: ''
})], AlertDialog.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], AlertDialog.prototype, "message", void 0);
__decorate([Prop({
  default: '560px'
})], AlertDialog.prototype, "width", void 0);
__decorate([Prop({
  default: false
})], AlertDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: true
})], AlertDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Watch('visible')], AlertDialog.prototype, "onVisiblePropChanges", null);
__decorate([Emit("on-dialog-close")], AlertDialog.prototype, "handleDialogClose", null);
AlertDialog = __decorate([Options({
  emits: ['on-dialog-close']
})], AlertDialog);
export default AlertDialog;