import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import WDialog from "@/components/wrappers/w-dialog.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { Emit, Prop } from "vue-property-decorator";
import delay from "delay";
import Icon from "@/components/icon/Icon.vue";
// @ts-ignore
import RecordRTC from 'recordrtc';
import WElMessage from "@/components/wrappers/w-elmessage.vue";
let MediaRecorder = class MediaRecorder extends Vue {
  constructor() {
    super(...arguments);
    this.currentCountDown = 0;
    this.recordRTC = null;
    this.isRecording = false;
    this.timeRecorded = 0;
    this.currentStream = null;
    this.cancelAsked = false;
    this.isPause = false;
  }
  mounted() {
    this.currentCountDown = this.countdown;
  }
  async startRecording() {
    this.currentCountDown = this.countdown;
    this.cancelAsked = false;
    // Get authorisation
    try {
      const mic = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      if (!mic) {
        this.onError(this.$t('mediaRecorder.mic.error'));
      }
    } catch (e) {
      this.onError(this.$t('mediaRecorder.mic.error'));
    }
    for (let i = this.countdown; i > -1; i--) {
      this.currentCountDown = i;
      this.onCountDown(this.currentCountDown);
      await delay(1000);
      if (this.cancelAsked) {
        this.cancelAsked = false;
        return;
      }
    }
    if (this.cancelAsked) {
      this.cancelAsked = false;
      return;
    }
    this.record();
  }
  async record() {
    this.currentStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      }
    });
    this.recordRTC = new RecordRTC(this.currentStream, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=opus',
      disableLogs: true,
      audioBitsPerSecond: 64000
    });
    this.recordRTC.startRecording();
    this.isRecording = true;
    this.onStart();
    let timeStart = new Date().getTime();
    this.timeRecorded = 0;
    while (this.isRecording) {
      timeStart = new Date().getTime();
      await delay(500);
      if (!this.isPause) {
        this.timeRecorded += new Date().getTime() - timeStart;
        this.onTimeRecord(this.timeRecorded);
      }
    }
  }
  pauseRecording() {
    this.recordRTC.pauseRecording();
    this.isPause = true;
  }
  resumeRecording() {
    this.recordRTC.resumeRecording();
    this.isPause = false;
  }
  async stopRecording() {
    if (this.isRecording) {
      var _this$currentStream;
      if (this.isPause) {
        this.resumeRecording();
        await delay(100);
      }
      this.recordRTC.stopRecording(async () => {
        let blob = this.recordRTC.getBlob();
        this.recordRTC.destroy();
        this.onBlobRecorded(null, blob);
      });
      (_this$currentStream = this.currentStream) === null || _this$currentStream === void 0 || _this$currentStream.getTracks().forEach(track => track.stop());
      this.currentStream = null;
      this.isRecording = false;
    }
  }
  cancel() {
    this.cancelAsked = true;
  }
  onBlobRecorded(event, blob) {
    return;
  }
  onError(message) {
    WElMessage.showErrorMessage(message);
  }
  onCountDown(counter) {
    return;
  }
  onTimeRecord(time) {
    return;
  }
  onStart() {
    return;
  }
};
__decorate([Prop({
  default: 3
})], MediaRecorder.prototype, "countdown", void 0);
__decorate([Prop({
  default: false
})], MediaRecorder.prototype, "audioOnly", void 0);
__decorate([Prop({
  default: true
})], MediaRecorder.prototype, "withButton", void 0);
__decorate([Emit('onBlobRecorded')], MediaRecorder.prototype, "onBlobRecorded", null);
__decorate([Emit('onError')], MediaRecorder.prototype, "onError", null);
__decorate([Emit("onCountDown")], MediaRecorder.prototype, "onCountDown", null);
__decorate([Emit("onTimeRecord")], MediaRecorder.prototype, "onTimeRecord", null);
__decorate([Emit('onStart')], MediaRecorder.prototype, "onStart", null);
MediaRecorder = __decorate([Options({
  components: {
    Icon,
    StandardButton,
    WDialog
  }
})], MediaRecorder);
export default MediaRecorder;