import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import IconButton from '@/components/buttons/IconButton.vue';
import axios from "axios";
import { ElMessageBox } from 'element-plus';
let ResetQuizButton = class ResetQuizButton extends Vue {
  resetQuiz() {
    let url = `/api/v2/quizz/delete-results-user`;
    axios.get(url, {
      params: {
        dataType: "json",
        quizzId: this.quizzId,
        idAchatCour: this.idAchatCour
      }
    }).then(() => {
      this.reloadAnalytics();
    });
  }
  reloadAnalytics() {
    window.location.assign(`/analytics/s/${this.speachId}`);
  }
  confirmResetQuiz() {
    ElMessageBox.confirm(this.$t('myAccount.speachSteering.popUpQuizz.confirmation')).then(this.resetQuiz);
  }
};
__decorate([Prop({
  default: false
})], ResetQuizButton.prototype, "disabled", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "quizzId", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "idAchatCour", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "speachId", void 0);
__decorate([Prop({
  default: false
})], ResetQuizButton.prototype, "displayAsIcon", void 0);
ResetQuizButton = __decorate([Options({
  components: {
    IconButton
  }
})], ResetQuizButton);
export default ResetQuizButton;