import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let SearchBarSearchField = class SearchBarSearchField extends Vue {
  constructor() {
    super(...arguments);
    this.searchTerm = this.initialSearchTerm;
    this.hasFocus = false;
  }
  get getPlaceHolder() {
    if (this.searchUser) {
      return this.$t("searchbar.placeholder.speach.all");
    } else {
      return this.$t("searchbar.placeholder.speach.only");
    }
  }
  mounted() {
    if (this.initialSearchTerm) {
      this.emitSearchTermChangedEvent();
    }
  }
  resetSearchTerm() {
    this.searchTerm = '';
    this.emitSearchTermChangedEvent();
  }
  emitSearchTermChangedEvent() {
    this.$emit("searchtermchanged", {
      searchTerm: this.searchTerm
    });
  }
  keyUpSearchInput(e) {
    // si l'utilisateur modifie le champ de recherche et que le champ devient vide,
    // on le passe à null pour masquer la page de recherche
    if (this.searchTerm === "") {
      this.resetSearchTerm();
    } else if (e.keyCode === 13) {
      if (this.searchTerm === null) {
        // si l'utilisateur clique sur ENTER on lance la recherche
        // si le champ de recherche est null on le passe à vide pour la recherche
        this.searchTerm = "";
        // modification de l'url pour permettre de revenir sur la recherche en cas de retour arrière
        this.emitSearchTermChangedEvent();
      } else {
        this.$emit("enterkeypressed", {
          searchTerm: this.searchTerm
        });
      }
    } else if (this.searchTerm) {
      this.emitSearchTermChangedEvent();
    }
  }
};
__decorate([Prop({
  default: false
})], SearchBarSearchField.prototype, "searchUser", void 0);
__decorate([Prop({
  required: true
})], SearchBarSearchField.prototype, "initialSearchTerm", void 0);
SearchBarSearchField = __decorate([Options({
  components: {
    Icon
  }
})], SearchBarSearchField);
export default SearchBarSearchField;