import { __decorate } from "tslib";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { notificationTypeAsMap } from "@/models/notificationType";
import { isAdmin, isAuthor } from "@/util/rolesUtils";
import WSelect from "@/components/form/w-select.vue";
import NotificationParametersTypeNotification from "@/components/notifications/subcomponent/NotificationParametersTypeNotification.vue";
let NotificationParameters = class NotificationParameters extends Vue {
  constructor() {
    super(...arguments);
    this.typeNotifications = [];
    this.frequencySelected = "NONE";
    this.frequencyOptions = [];
  }
  get isAdmin() {
    return isAdmin();
  }
  get isAuthor() {
    return isAuthor();
  }
  get typeNotificationAdmin() {
    return this.typeNotifications.filter(el => {
      return el.category === 'ADMIN';
    });
  }
  get typeNotificationAuthor() {
    return this.typeNotifications.filter(el => {
      return el.category === 'TRAINER';
    });
  }
  get typeNotificationUser() {
    return this.typeNotifications.filter(el => {
      return el.category === 'USER';
    });
  }
  mounted() {
    this.searchTypeNotification();
    this.frequencyOptions = [{
      id: "NONE",
      label: this.$t("notification.frequency.none")
    }, {
      id: "JOURNALIER",
      label: this.$t("notification.frequency.journalier")
    }, {
      id: "HEBDOMADAIRE",
      label: this.$t("notification.frequency.hebdomadaire")
    }, {
      id: "MENSUEL",
      label: this.$t("notification.frequency.mensuel")
    }];
  }
  searchTypeNotification() {
    axios.get("/api/v2/notifications/parameters", {
      params: {
        dataType: "json"
      }
    }).then(response => {
      this.typeNotifications = response.data.params;
      this.frequencySelected = response.data.frequencyNotification;
    });
  }
  changeFrequency(frequency) {
    axios.get("/api/v2/notifications/parameters/frequency", {
      params: {
        dataType: "json",
        frequency: frequency
      }
    });
  }
  getTypeNotificicationKey(type) {
    return notificationTypeAsMap[type];
  }
  activate(type) {
    axios.get("/api/v2/notifications/parameters/activate", {
      params: {
        dataType: "json",
        type: type
      }
    });
  }
  desactivate(type) {
    axios.get("/api/v2/notifications/parameters/desactivate", {
      params: {
        dataType: "json",
        type: type
      }
    });
  }
};
NotificationParameters = __decorate([Options({
  components: {
    NotificationParametersTypeNotification,
    WSelect
  }
})], NotificationParameters);
export default NotificationParameters;