import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import Icon from "@/components/icon/Icon.vue";
import NotificationIcon from "@/components/notifications/subcomponent/NotificationIcon.vue";
import NotificationHeaderList from "@/components/notifications/subcomponent/NotificationHeaderList.vue";
import NotificationFooterList from "@/components/notifications/subcomponent/NotificationFooterList.vue";
import NotificationList from "@/components/notifications/subcomponent/NotificationList.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import NotificationParameters from "@/components/notifications/NotificationParameters.vue";
let NotificationHeaderApp = class NotificationHeaderApp extends Vue {
  constructor() {
    super(...arguments);
    this.show = false;
    this.showParameters = false;
    this.unreadNotificationCount = 0;
    this.hasBeenAcquitted = false;
  }
  get settingsLabel() {
    return this.$t("notification.settings");
  }
  get notificationTitle() {
    return this.$t("notification.rubrique.title");
  }
  mounted() {
    axios.get("/api/v2/notifications/countUnread", {
      params: {
        dataType: "json"
      }
    }).then(response => {
      this.unreadNotificationCount = response.data;
    });
  }
  destroyed() {
    document.body.removeEventListener("click", this.closeNotification);
  }
  openParameters() {
    this.showParameters = true;
    this.show = false;
  }
  closeParameters() {
    this.showParameters = false;
    this.show = true;
  }
  closeOnlyParameters() {
    this.showParameters = false;
  }
  closeNotification() {
    this.show = false;
    this.showParameters = false;
  }
  togglePanelNotification(event) {
    event.stopPropagation();
    this.show = !this.show;
    if (this.show) {
      document.body.addEventListener("click", this.closeNotification);
      this.closeParameters();
    } else {
      document.body.removeEventListener("click", this.closeNotification);
    }
    if (!this.hasBeenAcquitted) {
      axios.post("/api/v2/notifications/acquit").then(() => {
        this.unreadNotificationCount = 0;
        this.hasBeenAcquitted = true;
      });
    }
  }
};
NotificationHeaderApp = __decorate([Options({
  components: {
    NotificationParameters,
    SidePanel,
    NotificationList,
    NotificationFooterList,
    NotificationHeaderList,
    NotificationIcon,
    Icon
  }
})], NotificationHeaderApp);
export default NotificationHeaderApp;