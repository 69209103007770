import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
let EmptyChart = class EmptyChart extends Vue {};
__decorate([Prop({
  required: true
})], EmptyChart.prototype, "url", void 0);
__decorate([Prop({
  default: false
})], EmptyChart.prototype, "loading", void 0);
EmptyChart = __decorate([Options({})], EmptyChart);
export default EmptyChart;