import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import GcuAgreementScreenForm from "@/components/gcu/GcuAgreementScreenForm.vue";
let GcuAgreementScreen = class GcuAgreementScreen extends Vue {
  userAccept() {
    window.location.assign("/gcu/acceptGcu");
  }
};
GcuAgreementScreen = __decorate([Options({
  components: {
    GcuAgreementScreenForm
  }
})], GcuAgreementScreen);
export default GcuAgreementScreen;