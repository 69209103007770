import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import ContentTile from "@/components/dashboard/tiles/ContentTile.vue";
import { getPublishedSpeachs, getUnpublishedSpeachs, getViewedSpeachs } from "@/util/dashboardUtils";
let DashboardSpeachsTiles = class DashboardSpeachsTiles extends Vue {
  constructor() {
    super(...arguments);
    this.publishedSpeachs = 0;
    this.unpublishedSpeachs = 0;
    this.viewedSpeachs = 0;
  }
  mounted() {
    getPublishedSpeachs().then(res => this.publishedSpeachs = res);
    getUnpublishedSpeachs().then(res => this.unpublishedSpeachs = res);
    getViewedSpeachs().then(res => this.viewedSpeachs = res);
  }
};
DashboardSpeachsTiles = __decorate([Options({
  components: {
    ContentTile,
    Tile
  }
})], DashboardSpeachsTiles);
export default DashboardSpeachsTiles;