import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37206e36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sliderTopLine",
  ref: "target"
};
const _hoisted_2 = {
  class: "sliderContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_icon_button = _resolveComponent("icon-button");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_standard_speach_card = _resolveComponent("standard-speach-card");
  const _component_AddCard = _resolveComponent("AddCard");
  const _component_slide = _resolveComponent("slide");
  const _component_carousel = _resolveComponent("carousel");
  const _directive_observe_visibility = _resolveDirective("observe-visibility");
  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "labelLink",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.goToFullPage && _ctx.goToFullPage(...args))
  }, [_createTextVNode(_toDisplayString(_ctx.title), 1), _ctx.title ? (_openBlock(), _createBlock(_component_icon, {
    key: 0,
    class: "chevron",
    icon: "chevron-right"
  })) : _createCommentVNode("", true)]), _ctx.overflow ? (_openBlock(), _createBlock(_component_icon_button, {
    key: 0,
    class: "buttonNavigation",
    color: "light2",
    icon: "chevron-left",
    onClick: _ctx.prev
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.overflow ? (_openBlock(), _createBlock(_component_icon_button, {
    key: 1,
    class: "buttonNavigation",
    color: "light2",
    icon: "chevron-right",
    onClick: _ctx.next
  }, null, 8, ["onClick"])) : _createCommentVNode("", true)])), [[_directive_observe_visibility, _ctx.visibilityChanged]]), _createElementVNode("div", _hoisted_2, [_withDirectives(_createVNode(_component_el_empty, {
    class: "emptyPanel",
    description: _ctx.$t('speachGrid.noResult')
  }, null, 8, ["description"]), [[_vShow, _ctx.listOfSpeach.length === 0]]), _createVNode(_component_carousel, {
    ref: "slider",
    mouseDrag: false,
    "items-to-show": 4,
    itemsToScroll: 1,
    snapAlign: 'start',
    breakpoints: _ctx.getBreakpoint
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfSpeach, (speach, index) => {
      return _openBlock(), _createBlock(_component_slide, {
        key: speach.speachID
      }, {
        default: _withCtx(() => [!_ctx.overflow || index < _ctx.listOfSpeach.length - 1 ? (_openBlock(), _createBlock(_component_standard_speach_card, {
          key: 0,
          outframe: _ctx.outframe,
          speach: speach,
          class: _normalizeClass(["speachCard", _ctx.getClass(index)]),
          commentsEnabled: _ctx.commentsEnabled,
          "speach-date-to-display": _ctx.speachDateToDisplay
        }, null, 8, ["outframe", "speach", "class", "commentsEnabled", "speach-date-to-display"])) : (_openBlock(), _createBlock(_component_AddCard, {
          key: 1,
          class: "speachCard",
          outframe: _ctx.outframe,
          url: _ctx.urlFullPage,
          icon: "redo",
          label: _ctx.title
        }, null, 8, ["outframe", "url", "label"]))]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["breakpoints"])])], 64);
}