import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
export default class WImage extends Vue {}
__decorate([Prop({
  required: true
})], WImage.prototype, "src", void 0);
__decorate([Prop({
  default: 'none'
}) // can be: fill, contain, cover, none or scale-down
], WImage.prototype, "fit", void 0);
__decorate([Prop({
  default: false
})], WImage.prototype, "hideOnClickModal", void 0);
__decorate([Prop({
  default: 'eager'
}) // can be: eager or lazy
], WImage.prototype, "loading", void 0);
__decorate([Prop({
  default: false
})], WImage.prototype, "lazy", void 0);
__decorate([Prop({
  default: ''
})], WImage.prototype, "alt", void 0);