import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-53c55982"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dashboard_users_tiles = _resolveComponent("dashboard-users-tiles");
  const _component_advanced_line_chart = _resolveComponent("advanced-line-chart");
  const _component_Tile = _resolveComponent("Tile");
  const _component_dashboard_table = _resolveComponent("dashboard-table");
  const _component_tile = _resolveComponent("tile");
  const _directive_observe_visibility = _resolveDirective("observe-visibility");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_dashboard_users_tiles), _createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_Tile, {
    title: _ctx.$t('admin.dashboard.users.activity.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_line_chart, {
      graphs: _ctx.usersActivity,
      height: 355,
      title: _ctx.$t('admin.dashboard.users.activity.title'),
      width: 500,
      "legend-tooltip-id": "userActivityLegend",
      loading: _ctx.usersActivityLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedUsersActivity]]), _withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.table.title.topSpeachMakerByRead'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_dashboard_table, {
      dataTable: _ctx.topSpeachmakerByWatch,
      params: _ctx.topSpeachmakerByWatchParams,
      loading: _ctx.topSpeachMakerWatchLoading
    }, null, 8, ["dataTable", "params", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedTopSpeachMakerWatch]])])], 64);
}