import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let NotificationIcon = class NotificationIcon extends Vue {
  get notificationNumberDisplay() {
    let value = "";
    if (this.notificationNumber > 99) {
      value = "+99";
    } else {
      value = this.notificationNumber + "";
    }
    return value;
  }
};
__decorate([Prop({
  required: true
})], NotificationIcon.prototype, "notificationNumber", void 0);
NotificationIcon = __decorate([Options({
  components: {
    Icon
  }
})], NotificationIcon);
export default NotificationIcon;