import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
let SpeachCell = class SpeachCell extends Vue {
  get backgroundURL() {
    return `url(${this.speach.img})`;
  }
  get imageSize() {
    if (this.size === 'small') {
      return '32px';
    } else if (this.size === 'large') {
      return '64px';
    }
    return '48px';
  }
  get cssVars() {
    return {
      '--parent-width': this.width,
      '--image-size': this.imageSize
    };
  }
};
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "speach", void 0);
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "searchTerm", void 0);
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "secondLineContent", void 0);
__decorate([Prop({
  default: '100%'
})], SpeachCell.prototype, "width", void 0);
__decorate([Prop({
  default: ''
})], SpeachCell.prototype, "size", void 0);
SpeachCell = __decorate([Options({
  components: {
    TextTag,
    TextHighlight
  }
})], SpeachCell);
export default SpeachCell;