import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import WSelect from "@/components/form/w-select.vue";
import WToggle from "@/components/form/w-toggle.vue";
import axios from "axios";
import StandardButton from "@/components/buttons/StandardButton.vue";
import delay from "delay";
let ExportMassScorm = class ExportMassScorm extends Vue {
  constructor() {
    super(...arguments);
    this.typeSelected = "SCORM_LITE";
    this.navigationInContent = false;
    this.listOfSpeach = [];
    this.currentSpeachToExport = 0;
    this.page = 1;
    this.waitingToDownload = "";
    this.types = [{
      value: "SCORM_LITE",
      label: "Scorm embed"
    }, {
      value: "SCORM_FULL",
      label: "Scorm classique"
    }];
  }
  mounted() {
    this.loadWeetsPage();
  }
  cancelExport() {
    window.location.reload();
  }
  async loadWeetsPage() {
    const result = await axios.get('/api/v2/admin/speach', {
      params: {
        page: this.page
      }
    });
    const pageWrapper = result.data;
    if (pageWrapper.result.length > 0) {
      this.listOfSpeach = this.listOfSpeach.concat(pageWrapper.result);
      this.page++;
      this.loadWeetsPage();
    }
  }
  async startDownload() {
    alert('Allow popup on this window for automatics download');
    const listOfSpeach = this.listOfSpeach;
    for (const speach of listOfSpeach) {
      if (speach && speach.speachID) {
        await this.launchJobScorm(speach);
        var isFileCreated = false;
        while (!isFileCreated) {
          await delay(2000);
          isFileCreated = await this.checkIfFileCreated();
        }
        var url = await this.downloadFileUrl();
        open(url, "_blank");
      }
    }
    this.currentSpeachToExport = 0;
    alert(this.listOfSpeach.length + " Exported");
  }
  async launchJobScorm(speach) {
    this.currentSpeachToExport++;
    const result = await axios.get('/administration/scorm/exportScorm', {
      params: {
        id: speach.id,
        contentNavigation: this.navigationInContent,
        type: this.typeSelected
      }
    });
    this.waitingToDownload = result.data.dataValue;
  }
  async checkIfFileCreated() {
    const result = await axios.get('/administration/scorm/checkScormCreated', {
      params: {
        name: this.waitingToDownload
      }
    });
    return result.data;
  }
  async downloadFileUrl() {
    return '/administration/scorm/download/' + this.waitingToDownload;
  }
};
ExportMassScorm = __decorate([Options({
  components: {
    StandardButton,
    WToggle,
    WSelect
  }
})], ExportMassScorm);
export default ExportMassScorm;