import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import StandardButton from "@/components/buttons/StandardButton.vue";
import PdpDisplay from "@/components/pdp/PdpDisplay.vue";
let PdpAgreementScreenForm = class PdpAgreementScreenForm extends Vue {
  constructor() {
    super(...arguments);
    this.pdpEntirelyScrolled = false;
  }
  handleScroll() {
    const pdpContainer = this.$refs.pdpContainer;
    if (pdpContainer.offsetHeight + pdpContainer.scrollTop >= pdpContainer.scrollHeight) {
      this.pdpEntirelyScrolled = true;
    }
  }
  clicked() {
    if (this.pdpEntirelyScrolled) {
      this.$emit("accept");
    }
  }
};
PdpAgreementScreenForm = __decorate([Options({
  components: {
    PdpDisplay,
    StandardButton
  }
})], PdpAgreementScreenForm);
export default PdpAgreementScreenForm;