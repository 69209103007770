import { __decorate } from "tslib";
import { Prop, Watch } from 'vue-property-decorator';
import { Vue } from "vue-class-component";
export default class WInput extends Vue {
  constructor() {
    super(...arguments);
    this.realValue = this.value;
  }
  onChange() {
    this.$emit('changeInput', this.realValue);
  }
  onChangeValue() {
    this.$emit('input', this.realValue);
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
}
__decorate([Prop({
  default: ''
})], WInput.prototype, "icon", void 0);
__decorate([Prop({
  default: 'default'
})], WInput.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WInput.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "placeholder", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "clearable", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "required", void 0);
__decorate([Prop({
  default: 'text'
})], WInput.prototype, "type", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "maxlength", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "showWordLimit", void 0);
__decorate([Prop({
  default: 2
})], WInput.prototype, "rows", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "noPrefix", void 0);
__decorate([Prop()], WInput.prototype, "dataTest", void 0);
__decorate([Watch('realValue')], WInput.prototype, "onChangeValue", null);
__decorate([Watch('value')], WInput.prototype, "onChangePropValue", null);