import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4430ac42"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "footer"
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$footerData, _ctx$footerData$items;
  return (_ctx$footerData = _ctx.footerData) !== null && _ctx$footerData !== void 0 && _ctx$footerData.isEnabled && (_ctx$footerData$items = _ctx.footerData.items) !== null && _ctx$footerData$items !== void 0 && _ctx$footerData$items.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerData.items, item => {
    return _openBlock(), _createElementBlock("span", {
      key: item.code,
      class: "footer__item"
    }, [_createElementVNode("a", {
      href: _ctx.FooterItemPathEnum[item.code],
      target: "_blank"
    }, _toDisplayString(item.label), 9, _hoisted_2)]);
  }), 128))])) : _createCommentVNode("", true);
}