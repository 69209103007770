import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
export default class UnsubscribeEmailNotificationSuccess extends Vue {
  constructor() {
    super(...arguments);
    this.displayGoToHomeBtn = false;
  }
  /**
   * Go to home page
   * @private
   */
  goToHome() {
    window.location.assign('/');
  }
}
__decorate([Prop()], UnsubscribeEmailNotificationSuccess.prototype, "displayGoToHomeBtn", void 0);