import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BaseSpeachCard from "@/components/card/BaseSpeachCard.vue";
import TextTag from "@/components/text/TextTag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import { Events } from "@/events/events";
import MenuSpeachCard from "@/components/card/menu/MenuSpeachCard.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import { WorkflowStatus } from "@/models/workflowStatusEnum";
import { DateFilterEnum } from "@/models/DateFilterEnum";
let StandardSpeachCard = class StandardSpeachCard extends Vue {
  constructor() {
    super(...arguments);
    this.bookmarkedState = this.speach.isFavori;
    this.hover = false;
  }
  shareSpeach() {
    trig(Events.DIFFUSER_COUR, {
      id: this.speach.id,
      isPublie: this.speach.publication,
      isDuringDelayPeriod: this.speach.delayed_till
    });
  }
  goToSpeachEdit() {
    window.location.assign(`/recorder/edit/${this.speach.speachID}`);
  }
  blockHover(hover) {
    this.hover = hover;
  }
  get isWFRejected() {
    return this.speach.wf_state != WorkflowStatus.AWAITING_VALIDATION;
  }
};
__decorate([Prop({
  required: true
})], StandardSpeachCard.prototype, "speach", void 0);
__decorate([Prop({
  default: false
})], StandardSpeachCard.prototype, "outframe", void 0);
__decorate([Prop({
  default: false
})], StandardSpeachCard.prototype, "loadRegulated", void 0);
__decorate([Prop({
  required: true
})], StandardSpeachCard.prototype, "commentsEnabled", void 0);
__decorate([Prop({
  default: false
})], StandardSpeachCard.prototype, "addButton", void 0);
__decorate([Prop({
  default: false
})], StandardSpeachCard.prototype, "removeButton", void 0);
__decorate([Prop({
  default: DateFilterEnum.LAST_MAJ
})], StandardSpeachCard.prototype, "speachDateToDisplay", void 0);
StandardSpeachCard = __decorate([Options({
  components: {
    WToolTip,
    Icon,
    MenuSpeachCard,
    IconButton,
    TextTag,
    BaseSpeachCard
  },
  emits: ['addSpeachTo', 'removeSpeachFrom']
})], StandardSpeachCard);
export default StandardSpeachCard;