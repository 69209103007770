import { __decorate } from "tslib";
/** Composant qui affiche les boutons de navigation */
import { Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
export default class StandardButton extends Vue {
  click() {
    if (!this.disabled) {
      this.$emit("action");
    }
  }
  get buttonSubClass() {
    let subClass = 'light ';
    if (this.disabled) {
      subClass += 'disabled';
    }
    if (this.expanded) {
      subClass += ' _fullWidth';
    }
    return subClass;
  }
  get marginsClass() {
    if (this.verySmallMargins) {
      return '_withVerySmallMargin';
    } else if (this.expanded) {
      return '_withFlexibleMargin';
    } else {
      return '_withSmallMargin';
    }
  }
}
__decorate([Prop({
  default: false
})], StandardButton.prototype, "disabled", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "verySmallMargins", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "expanded", void 0);