import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import DashBoardLineChart from "@/components/dashboard/charts/DashBoardLineChart.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedLineChart = class AdvancedLineChart extends Vue {};
__decorate([Prop({
  required: true
})], AdvancedLineChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: 500
})], AdvancedLineChart.prototype, "width", void 0);
__decorate([Prop({
  default: 500
})], AdvancedLineChart.prototype, "height", void 0);
__decorate([Prop({
  required: true
})], AdvancedLineChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: ""
})], AdvancedLineChart.prototype, "title", void 0);
__decorate([Prop({
  default: false
})], AdvancedLineChart.prototype, "big", void 0);
__decorate([Prop({
  type: Boolean,
  default: false
})], AdvancedLineChart.prototype, "cumulative", void 0);
__decorate([Prop({
  default: false
})], AdvancedLineChart.prototype, "loading", void 0);
AdvancedLineChart = __decorate([Options({
  components: {
    EmptyChart,
    DashBoardLineChart
  }
})], AdvancedLineChart);
export default AdvancedLineChart;