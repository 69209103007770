import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
let UserCell = class UserCell extends Vue {
  get userIsInstanceOfUserInfos() {
    return "label" in this.user;
  }
  get imageSize() {
    if (this.size === 'small') {
      return '24px';
    } else if (this.size === 'large') {
      return '48px';
    }
    return '32px';
  }
  get cssVars() {
    return {
      '--parent-width': this.width,
      '--image-size': this.imageSize
    };
  }
};
__decorate([Prop({
  required: true
})], UserCell.prototype, "user", void 0);
__decorate([Prop({
  required: true
})], UserCell.prototype, "searchTerm", void 0);
__decorate([Prop({
  required: true
})], UserCell.prototype, "secondLineContent", void 0);
__decorate([Prop({
  default: '100%'
})], UserCell.prototype, "width", void 0);
__decorate([Prop({
  default: ''
})], UserCell.prototype, "size", void 0);
UserCell = __decorate([Options({
  components: {
    TextTag,
    TextHighlight
  }
})], UserCell);
export default UserCell;