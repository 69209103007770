import { Vue } from "vue-class-component";
import axios from "axios";
import { FooterItemPathEnum } from "@/models/footerItemPathEnum";
export default class Footer extends Vue {
  constructor() {
    super(...arguments);
    this.FooterItemPathEnum = FooterItemPathEnum;
    this.footerData = {
      isEnabled: false,
      items: []
    };
  }
  mounted() {
    this.loadFooterData();
  }
  loadFooterData() {
    axios.get('/api/v2/footer').then(({
      data
    }) => {
      this.footerData = data;
    });
  }
}