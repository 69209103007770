import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import ContentTile from "@/components/dashboard/tiles/ContentTile.vue";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import { getAdmins, getApprovers, getSpeachMakers, getUsers, isWorkflowEnable } from "@/util/dashboardUtils";
let DashboardUsersTiles = class DashboardUsersTiles extends Vue {
  constructor() {
    super(...arguments);
    this.users = 0;
    this.admins = 0;
    this.speachmakers = 0;
    this.approvers = 0;
    this.showApprovers = false;
  }
  get tileWidth() {
    return this.showApprovers ? 25 : 33;
  }
  mounted() {
    getUsers().then(res => this.users = res);
    getAdmins().then(res => this.admins = res);
    getSpeachMakers().then(res => this.speachmakers = res);
    isWorkflowEnable().then(res => {
      this.showApprovers = res;
      if (this.showApprovers) {
        getApprovers().then(res => this.approvers = res);
      }
    });
  }
};
DashboardUsersTiles = __decorate([Options({
  components: {
    Tile,
    ContentTile
  }
})], DashboardUsersTiles);
export default DashboardUsersTiles;