import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import StandardButton from "@/components/buttons/StandardButton.vue";
import GridLayout from "@/components/layout/GridLayout.vue";
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
let GetStartedCard = class GetStartedCard extends Vue {};
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "title", void 0);
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "content", void 0);
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "buttonLabel", void 0);
__decorate([Prop({
  default: true
})], GetStartedCard.prototype, "closeButton", void 0);
GetStartedCard = __decorate([Options({
  components: {
    Icon,
    GridLayout,
    StandardButton
  }
})], GetStartedCard);
export default GetStartedCard;