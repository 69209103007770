import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-713c6980"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "listFooter"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "seeAll",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.gotToNotificationPage && _ctx.gotToNotificationPage(...args))
  }, _toDisplayString(_ctx.$t("notification.see.all")), 1)]);
}