import { __decorate } from "tslib";
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { Prop, Watch } from "vue-property-decorator";
import { Vue, Options } from "vue-class-component";
import { getLegend, getTooltip } from "@/util/chartOptions";
import dayjs from "dayjs";
let DashBoardLineChart = class DashBoardLineChart extends Vue {
  constructor() {
    super(...arguments);
    this.datasets = [];
    this.startDates = [];
    this.endDates = [];
    this.legends = [];
    this.loading = false;
  }
  mounted() {
    Chart.register(...registerables);
    setTimeout(() => {
      this.loading = true;
    }, 10);
  }
  onDataChange() {
    if (this.graphs) {
      this.startDates = this.graphs[0].data.map(d => {
        return dayjs(d.startDate).format(this.$t('formatDateDayMonth').toString());
      });
      this.endDates = this.graphs[0].data.map(d => {
        return dayjs(d.endDate).format(this.$t('formatDateDayMonth').toString());
      });
      this.datasets = this.graphs.map(g => {
        const gradient = this.getDegrade(g.rgbaColor);
        return {
          label: g.legend,
          fill: true,
          tension: 0,
          backgroundColor: gradient,
          borderColor: g.rgbaColor,
          pointBorderColor: g.rgbaColor,
          pointBackgroundColor: g.rgbaColor,
          borderWidth: 2,
          xAxis: this.startDates,
          pointBorderWidth: 2,
          data: g.data.map(d => {
            return d.value;
          }),
          tooltipFirstPeriodDates: this.startDates,
          tooltipSecondPeriodDates: this.endDates
        };
      });
      this.legends = this.graphs.map(g => g.tooltipLegend);
    }
  }
  getDegrade(rgba) {
    if (this.$refs.linechart) {
      // @ts-ignore
      var canvas = this.$refs.linechart.canvasRef;
      var ctx = canvas.getContext("2d");
      if (ctx !== null) {
        var degrade = ctx.createLinearGradient(0, 0, 0, 300);
        degrade.addColorStop(1, "rgba(255, 255, 255, 0)");
        degrade.addColorStop(0, "rgb(221, 221, 246)");
        return degrade;
      }
    }
    return rgba;
  }
  get dataGraph() {
    return {
      labels: this.endDates,
      datasets: this.datasets
    };
  }
  get options() {
    return {
      plugins: {
        legend: getLegend(this.legends, this.legendTooltipId),
        tooltip: getTooltip("nearest", this.title, this.$t, this.cumulative)
      },
      scales: {
        xAxisID: {
          grid: {
            drawOnChartArea: false,
            drawBorder: false,
            display: false
          }
        },
        yAxisID: {
          grid: {
            drawBorder: false
          },
          beginAtZero: true,
          ticks: {
            callback: value => {
              if (value % 2 === 0) {
                return value;
              } else {
                return null;
              }
            }
          }
        }
      }
    };
  }
};
__decorate([Prop({
  required: true
})], DashBoardLineChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: ""
})], DashBoardLineChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: ""
})], DashBoardLineChart.prototype, "title", void 0);
__decorate([Prop({
  type: Boolean,
  default: false
})], DashBoardLineChart.prototype, "cumulative", void 0);
__decorate([Watch("graphs")], DashBoardLineChart.prototype, "onDataChange", null);
DashBoardLineChart = __decorate([Options({
  components: {
    LineChart
  }
})], DashBoardLineChart);
export default DashBoardLineChart;