import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import DashBoardDoughnutChart from "@/components/dashboard/charts/DashBoardDoughnutChart.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedDoughnutChart = class AdvancedDoughnutChart extends Vue {};
__decorate([Prop({
  required: true
})], AdvancedDoughnutChart.prototype, "nameValues", void 0);
__decorate([Prop({
  default: ''
})], AdvancedDoughnutChart.prototype, "title", void 0);
__decorate([Prop({
  default: ""
})], AdvancedDoughnutChart.prototype, "labelTooltip", void 0);
__decorate([Prop({
  default: ""
})], AdvancedDoughnutChart.prototype, "labelTooltipPlural", void 0);
__decorate([Prop({
  default: false
})], AdvancedDoughnutChart.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], AdvancedDoughnutChart.prototype, "loading", void 0);
AdvancedDoughnutChart = __decorate([Options({
  components: {
    EmptyChart,
    DashBoardDoughnutChart
  }
})], AdvancedDoughnutChart);
export default AdvancedDoughnutChart;