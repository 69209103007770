import { __decorate } from "tslib";
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Vue } from "vue-class-component";
export default class WSelectAdvanced extends Vue {
  constructor() {
    super(...arguments);
    this.filterable = false;
    this.realValue = [];
  }
  mounted() {
    this.realValue = this.value;
  }
  onChangeValue() {
    return this.realValue;
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
  selectAll() {
    if (this.multiple) {
      if (this.realValue.length === this.options.length) {
        this.realValue = [];
      } else {
        this.realValue = this.options.map(option => option[this.valueKey]);
      }
      this.onChangeValue();
    }
  }
  isSelected(item) {
    if (Array.isArray(this.realValue)) {
      return this.realValue.filter(currentItem => currentItem === item).length > 0;
    } else {
      return this.realValue === item;
    }
  }
}
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WSelectAdvanced.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "placeholder", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "label", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "required", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "loading", void 0);
__decorate([Prop({
  default: "value"
})], WSelectAdvanced.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "label"
})], WSelectAdvanced.prototype, "labelKey", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "multiple", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "optionAll", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "collapseTags", void 0);
__decorate([Prop()], WSelectAdvanced.prototype, "filterable", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "classClickFilter", void 0);
__decorate([Prop()], WSelectAdvanced.prototype, "dataTest", void 0);
__decorate([Prop({
  required: true,
  default: []
})], WSelectAdvanced.prototype, "options", void 0);
__decorate([Emit('change')], WSelectAdvanced.prototype, "onChangeValue", null);
__decorate([Watch('value')], WSelectAdvanced.prototype, "onChangePropValue", null);