import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1be58205"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "containerWithShadow"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "row"
};
const _hoisted_6 = {
  class: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_text_highlight = _resolveComponent("text-highlight");
  const _component_TextTag = _resolveComponent("TextTag");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "container",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.goToUser())
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      'background-image': 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ' + _ctx.backgroundURL + ''
    }),
    class: "blur-background"
  }, null, 4), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    alt: "userImage",
    class: "userImage",
    src: _ctx.user.img
  }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_text_highlight, {
    label: _ctx.user.firstName + ' ' + _ctx.user.lastName,
    term: _ctx.searchTerm,
    class: "name"
  }, null, 8, ["label", "term"])]), _createElementVNode("div", _hoisted_6, [_ctx.user.admin ? (_openBlock(), _createBlock(_component_TextTag, {
    key: 0,
    class: "tag"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('usercard.admin')), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _ctx.user.trainer ? (_openBlock(), _createBlock(_component_TextTag, {
    key: 1,
    class: "tag"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('usercard.author')), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _ctx.user.approver && _ctx.workflowEnabled ? (_openBlock(), _createBlock(_component_TextTag, {
    key: 2,
    class: "tag"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('usercard.approver')), 1)]),
    _: 1
  })) : _createCommentVNode("", true)])])])]);
}