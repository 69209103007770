import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
import { goToUser } from "@/util/routeUtil";
let UserCard = class UserCard extends Vue {
  get backgroundURL() {
    return `url(${this.user.img})`;
  }
  goToUser() {
    goToUser(this.user);
  }
};
__decorate([Prop({
  required: true
})], UserCard.prototype, "user", void 0);
__decorate([Prop({
  required: true
})], UserCard.prototype, "searchTerm", void 0);
__decorate([Prop({
  default: false
})], UserCard.prototype, "workflowEnabled", void 0);
UserCard = __decorate([Options({
  components: {
    TextTag,
    TextHighlight
  }
})], UserCard);
export default UserCard;