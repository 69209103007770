import { __decorate } from "tslib";
import { Prop, Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { Metric, Period } from "@/models/dashboard.model";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import AdvancedBarChart from "@/components/dashboard/charts/AdvancedBarChart.vue";
import DashboardTable from "@/components/dashboard/charts/DashboardTable.vue";
import DashboardSpeachsTiles from "@/components/dashboard/tiles/DashboardSpeachsTiles.vue";
import { getSpeachesCategory, getSpeachWatch, getTimeSerie, getTopSpeachData } from "@/util/dashboardUtils";
import AdvancedLineChart from "@/components/dashboard/charts/AdvancedLineChart.vue";
import AdvancedDoughnutChart from "@/components/dashboard/charts/AdvancedDoughnutChart.vue";
let DashboardSpeachesTab = class DashboardSpeachesTab extends Vue {
  constructor() {
    super(...arguments);
    this.topSpeachByWatch = [];
    this.uniqueReads = [];
    this.creations = [];
    this.publications = [];
    this.creationsCumulative = [];
    this.readingCumulative = [];
    this.speachesCategory = [];
    this.creationsCumulativeVisible = false;
    this.readingCumulativeVisible = false;
    this.readActivityVisible = false;
    this.topSpeachWatchVisible = false;
    this.creationVisible = false;
    this.speachesCategoryVisible = false;
    this.creationsCumulativeLoading = false;
    this.readingCumulativeLoading = false;
    this.readActivityLoading = false;
    this.topSpeachWatchLoading = false;
    this.creationLoading = false;
    this.speachesCategoryLoading = false;
    //
  }
  async visibilityChangedReadActivity(value) {
    this.readActivityVisible = value;
    if (value && this.uniqueReads.length === 0) {
      this.readActivityLoading = true;
      getTimeSerie(Metric.UNIQUE_READ, this.selectedPeriod, false).then(res => this.uniqueReads = res).finally(() => this.readActivityLoading = false);
    }
  }
  async visibilityChangedCreationCumulative(value) {
    this.creationsCumulativeVisible = value;
    if (value && this.creationsCumulative.length === 0) {
      this.creationsCumulativeLoading = true;
      getTimeSerie(Metric.CREATION, this.selectedPeriod, true).then(res => this.creationsCumulative = res).finally(() => this.creationsCumulativeLoading = false);
    }
  }
  async visibilityChangedReadingCumulative(value) {
    this.readingCumulativeVisible = value;
    if (value && this.readingCumulative.length === 0) {
      this.readingCumulativeLoading = true;
      getTimeSerie(Metric.UNIQUE_READ, this.selectedPeriod, true).then(res => this.readingCumulative = res).finally(() => this.readingCumulativeLoading = false);
    }
  }
  async visibilityChangedSpeachesCategory(value) {
    this.speachesCategoryVisible = value;
    if (value && this.speachesCategory.length === 0) {
      this.speachesCategoryLoading = true;
      getSpeachesCategory(this.selectedPeriod).then(res => this.speachesCategory = res).finally(() => this.speachesCategoryLoading = false);
    }
  }
  async visibilityChangedTopSpeachWatch(value) {
    this.topSpeachWatchVisible = value;
    if (value && this.topSpeachByWatch.length === 0) {
      this.topSpeachWatchLoading = true;
      getSpeachWatch(this.selectedPeriod).then(res => this.topSpeachByWatch = getTopSpeachData(res)).finally(() => this.topSpeachWatchLoading = false);
    }
  }
  async visibilityChangedCreation(value) {
    this.creationVisible = value;
    if (value && this.publications.length === 0) {
      this.creationLoading = true;
      getTimeSerie(Metric.PUBLICATION, this.selectedPeriod, false).then(res => this.publications = res).finally(() => this.creationLoading = false);
    }
    if (value && this.creations.length === 0) {
      this.creationLoading = true;
      getTimeSerie(Metric.CREATION, this.selectedPeriod, false).then(res => this.creations = res).finally(() => this.creationLoading = false);
    }
  }
  get topSpeachByWatchParams() {
    return [{
      column: this.$t("admin.dashboard.table.headers.speachTitle"),
      key: "speachTitle",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.firstPublishDate"),
      key: "publishDateFormatted",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.read"),
      key: "nbWatch",
      width: "20%"
    }];
  }
  get creationsCumulativeGraphe() {
    return [{
      legend: this.$t("admin.dashboard.creationsCumulative.legend.creations"),
      tooltipLegend: this.$t("admin.dashboard.creationsCumulative.legend.tooltip.creations"),
      rgbaColor: "rgba(147, 147, 241,1)",
      data: this.creationsCumulative
    }];
  }
  get readingCumulativeGraphe() {
    return [{
      legend: this.$t("admin.dashboard.readingCumulative.legend.reads"),
      tooltipLegend: this.$t("admin.dashboard.readingCumulative.legend.tooltip.reads"),
      rgbaColor: "rgba(147, 147, 241,1)",
      data: this.readingCumulative
    }];
  }
  get readActivity() {
    return [{
      legend: this.$t("admin.dashboard.readingActivity.legend.readers"),
      tooltipLegend: this.$t("admin.dashboard.readingActivity.legend.tooltip.readers"),
      rgbaColor: "rgba(95, 95, 255,1)",
      data: this.uniqueReads
    }];
  }
  //
  get creationTunnel() {
    return [{
      legend: this.$t("admin.dashboard.creation.tunnel.legend.publish.speach"),
      tooltipLegend: this.$t("admin.dashboard.creation.tunnel.legend.tooltip.publish.speach"),
      rgbaColor: "rgb(147, 147, 241,1)",
      data: this.publications
    }, {
      legend: this.$t("admin.dashboard.creation.tunnel.legend.draft.speach"),
      tooltipLegend: this.$t("admin.dashboard.creation.tunnel.legend.tooltip.draft.speach"),
      rgbaColor: "rgba(95, 95, 255,1)",
      data: this.creations
    }];
  }
  changeSelectedPeriod() {
    this.resetAllReports();
  }
  async resetAllReports() {
    this.topSpeachByWatch = [];
    this.uniqueReads = [];
    this.creations = [];
    this.publications = [];
    this.creationsCumulative = [];
    this.readingCumulative = [];
    this.speachesCategory = [];
    this.visibilityChangedCreationCumulative(this.creationsCumulativeVisible);
    this.visibilityChangedReadingCumulative(this.readingCumulativeVisible);
    this.visibilityChangedCreation(this.creationVisible);
    this.visibilityChangedReadActivity(this.readActivityVisible);
    this.visibilityChangedTopSpeachWatch(this.topSpeachWatchVisible);
    this.visibilityChangedSpeachesCategory(this.speachesCategoryVisible);
  }
};
__decorate([Prop({
  default: Period.THREE_MONTHS
})], DashboardSpeachesTab.prototype, "selectedPeriod", void 0);
__decorate([Watch("selectedPeriod")], DashboardSpeachesTab.prototype, "changeSelectedPeriod", null);
DashboardSpeachesTab = __decorate([Options({
  components: {
    AdvancedDoughnutChart,
    AdvancedLineChart,
    DashboardSpeachsTiles,
    DashboardTable,
    AdvancedBarChart,
    Tile
  }
})], DashboardSpeachesTab);
export default DashboardSpeachesTab;