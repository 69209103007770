import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let WTag = class WTag extends Vue {
  removeTag(event) {
    return;
  }
};
__decorate([Prop({
  required: true
})], WTag.prototype, "textTag", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "round", void 0);
__decorate([Prop({
  default: 'default'
}) // values : mini, small, medium, large
], WTag.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WTag.prototype, "closable", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "ellipsis", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "withToolTip", void 0);
__decorate([Emit("removeTag")], WTag.prototype, "removeTag", null);
WTag = __decorate([Options({
  components: {
    WToolTip
  }
})], WTag);
export default WTag;