import { __decorate } from "tslib";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
export default class ImageBackground extends Vue {
  get radiusApplied() {
    if (this.radius !== '') {
      return {
        'border-radius': this.radius
      };
    } else {
      return '';
    }
  }
}
__decorate([Prop({
  required: true
})], ImageBackground.prototype, "imageUrl", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "titre", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "sousTitre", void 0);
__decorate([Prop({
  default: '266px'
})], ImageBackground.prototype, "width", void 0);
__decorate([Prop({
  default: '169px'
})], ImageBackground.prototype, "height", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "radius", void 0);
__decorate([Prop({
  default: 'contain'
})], ImageBackground.prototype, "backgroundSize", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "marginTop", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "idDivBackground", void 0);