
export enum Events{
    DIFFUSER_COUR="DIFFUSER_COUR",
    DUPLICATE_COUR_THUMBNAIL="DUPLICATE_COUR_THUMBNAIL",
    MOUNT_VUE_COMPONENT="MOUNT_VUE_COMPONENT",
    OPEN_POPIN_IMAGE="OPEN_POPIN_IMAGE",
    AFFICHER_EDITION_CHAPITRE="AFFICHER_EDITION_CHAPITRE",

    // VIDEO PLAYER
    VIDEO_AUDIO_TRACK_SELECTED = "VIDEO_AUDIO_TRACK_SELECTED",
    RESET_AUDIO_TRACK = "RESET_AUDIO_TRACK",
    SELECT_AUDIO_TRACK='SELECT_AUDIO_TRACK',
    VIDEO_PLAYING="VIDEO_PLAYING",
    VIDEO_READY="VIDEO_READY",

    // DUE DATE SETTINGS
    BADGE_SET = "BADGE_SET",
    BADGE_UNSET = "BADGE_UNSET",
    DUE_DATE_EXPIRED_SET = "DUE_DATE_EXPIRED_SET",
    DUE_DATE_EXPIRED_UNSET = "DUE_DATE_EXPIRED_UNSET",
}
