import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
let WDate = class WDate extends Vue {
  get dateToFormattedString() {
    let dateToFormat = this.date;
    if (this.timestamp !== null) {
      dateToFormat = new Date(this.timestamp);
    } else if (this.stringDate !== '') {
      dateToFormat = new Date(this.stringDate);
    }
    if (dateToFormat === null) {
      return '-';
    }
    return this.$d(dateToFormat);
  }
};
__decorate([Prop({
  default: null
})], WDate.prototype, "date", void 0);
__decorate([Prop({
  default: ''
})], WDate.prototype, "stringDate", void 0);
__decorate([Prop({
  default: null
})], WDate.prototype, "timestamp", void 0);
WDate = __decorate([Options({})], WDate);
export default WDate;