import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
let WDialog = class WDialog extends Vue {
  constructor() {
    super(...arguments);
    this.isVisible = false;
  }
  onVisibleChange() {
    this.isVisible = this.visible;
  }
  mounted() {
    this.isVisible = this.visible;
  }
  emitCloseEvent() {
    return;
  }
};
__decorate([Prop({
  required: true
})], WDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: ''
})], WDialog.prototype, "title", void 0);
__decorate([Prop({
  default: '600px'
})], WDialog.prototype, "width", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "modal", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Prop({
  default: false
})], WDialog.prototype, "appendToBody", void 0);
__decorate([Prop({
  fullscreen: false
})], WDialog.prototype, "fullscreen", void 0);
__decorate([Prop({
  default: ''
})], WDialog.prototype, "customClass", void 0);
__decorate([Watch('visible')], WDialog.prototype, "onVisibleChange", null);
__decorate([Emit('onDialogClose')], WDialog.prototype, "emitCloseEvent", null);
WDialog = __decorate([Options({
  // remove weird warning
  emits: ['onDialogClose']
})], WDialog);
export default WDialog;