import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { getLegend, getRotation, getTooltip } from "@/util/chartOptions";
import dayjs from "dayjs";
let DashboardBarChart = class DashboardBarChart extends Vue {
  constructor() {
    super(...arguments);
    this.datasets = [];
    this.startDates = [];
    this.endDates = [];
    this.legends = [];
    this.loading = false;
  }
  mounted() {
    Chart.register(...registerables);
    setTimeout(() => {
      this.loading = true;
    }, 10);
  }
  onDataChange() {
    if (this.graphs) {
      this.startDates = this.graphs[0].data.map(d => dayjs(d.startDate).format(this.$t('formatDateDayMonth').toString()));
      this.endDates = this.graphs[0].data.map(d => dayjs(d.endDate).format(this.$t('formatDateDayMonth').toString()));
      this.datasets = this.graphs.map(g => {
        return {
          label: g.legend,
          fill: true,
          tension: 2,
          backgroundColor: g.rgbaColor,
          barThickness: "flex",
          barPercentage: 0.7,
          data: g.data.map(d => d.value),
          tooltipFirstPeriodDates: this.startDates,
          tooltipSecondPeriodDates: this.endDates
        };
      });
      this.legends = this.graphs.map(g => g.tooltipLegend);
    }
  }
  get dataGraph() {
    // Overwriting base render method with actual data.
    return {
      labels: this.endDates,
      datasets: this.datasets
    };
  }
  get options() {
    return {
      plugins: {
        legend: getLegend(this.legends, this.legendTooltipId),
        tooltip: getTooltip("nearest", this.title, this.$t)
      },
      scales: {
        y: {
          grid: {
            drawBorder: false
          },
          beginAtZero: true,
          ticks: {
            callback: value => {
              if (value % 1 === 0) {
                return value;
              } else {
                return null;
              }
            }
          }
        },
        x: {
          ticks: getRotation(this.doRotate),
          grid: {
            drawOnChartArea: false,
            offsetGridLines: false
          }
        }
      }
    };
  }
};
__decorate([Prop({
  required: true
})], DashboardBarChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: false
})], DashboardBarChart.prototype, "doRotate", void 0);
__decorate([Prop({
  default: ''
})], DashboardBarChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: ''
})], DashboardBarChart.prototype, "title", void 0);
__decorate([Watch("graphs")], DashboardBarChart.prototype, "onDataChange", null);
DashboardBarChart = __decorate([Options({
  components: {
    BarChart
  }
})], DashboardBarChart);
export default DashboardBarChart;