import { __decorate } from "tslib";
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading';
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/loader/Loader.vue";
import NotificationItem from "@/components/notifications/subcomponent/NotificationItem.vue";
/**
 * @return {Object} l'état initial du composant
 */
function getNotificationsInitState() {
  return {
    notificationsFound: [],
    totalNumberOfNotifications: 0,
    currentPage: 1
  };
}
let NotificationList = class NotificationList extends Vue {
  constructor() {
    super(...arguments);
    // todo WTF?
    this.notifications = getNotificationsInitState();
    this.loading = false;
    this.loaded = false;
  }
  get numberOfNotificationsDisplayed() {
    return this.notifications.notificationsFound.length;
  }
  get notificationList() {
    return this.notifications.notificationsFound;
  }
  get typeNotificationAsParameter() {
    return this.typeNotification === "" ? null : this.typeNotification;
  }
  onTypeNotificationChange(typeNotification) {
    this.notifications = getNotificationsInitState();
    this.loading = false;
    this.loaded = false;
    this.initComponent();
  }
  mounted() {
    this.initComponent();
  }
  initComponent() {
    axios.get("/api/v2/notifications/count", {
      params: {
        dataType: "json",
        type: this.typeNotificationAsParameter
      }
    }).then(response => {
      this.notifications.totalNumberOfNotifications = response.data;
    });
    this.getNotification();
  }
  getNotification() {
    this.loading = true;
    return axios.get("/api/v2/notifications", {
      params: {
        dataType: "json",
        page: this.notifications.currentPage,
        size: 10,
        type: this.typeNotificationAsParameter
      }
    }).then(this.handleNotificationResponse);
  }
  infiniteHandler($state) {
    if (this.numberOfNotificationsDisplayed < this.notifications.totalNumberOfNotifications) {
      this.getNotification().then(() => {
        $state.loaded();
        if (this.numberOfNotificationsDisplayed === this.notifications.totalNumberOfNotifications) {
          $state.complete();
        }
      });
    } else {
      $state.loaded();
      $state.complete();
    }
  }
  handleNotificationResponse(notificationResponse) {
    this.notifications.currentPage = this.notifications.currentPage + 1;
    let newNotificationsFromCall = notificationResponse.data;
    if (this.notifications.currentPage === 2) {
      // reset infinite loading when showing page 1 and component already exist
      if (this.$refs.infiniteLoader) {
        this.resetInfiniteLoader();
      }
      this.notifications.notificationsFound = newNotificationsFromCall;
    } else {
      this.notifications.notificationsFound = this.notifications.notificationsFound.concat(newNotificationsFromCall);
    }
    this.loading = false;
    this.loaded = true;
  }
  resetInfiniteLoader() {
    if (this.$refs.infiniteLoader) {
      // @ts-ignore
      this.$refs.infiniteLoader.$emit("$InfiniteLoading:reset", {
        target: this.$refs.infiniteLoader
      });
    }
  }
};
__decorate([Prop({
  default: null
})], NotificationList.prototype, "typeNotification", void 0);
__decorate([Watch("typeNotification")], NotificationList.prototype, "onTypeNotificationChange", null);
NotificationList = __decorate([Options({
  components: {
    NotificationItem,
    Loader,
    InfiniteLoading
  }
})], NotificationList);
export default NotificationList;