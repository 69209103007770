import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let IconButton = class IconButton extends Vue {
  click() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
};
__decorate([Prop({
  default: ''
})], IconButton.prototype, "className", void 0);
__decorate([Prop({
  required: true
})], IconButton.prototype, "icon", void 0);
__decorate([Prop({
  default: 'default'
})], IconButton.prototype, "size", void 0);
__decorate([Prop({
  default: false
})], IconButton.prototype, "disabled", void 0);
__decorate([Prop({
  default: 'primary'
})], IconButton.prototype, "color", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "tooltip", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "tooltipPosition", void 0);
__decorate([Prop({
  default: ''
})], IconButton.prototype, "text", void 0);
IconButton = __decorate([Options({
  components: {
    WToolTip,
    Icon
  },
  emits: ["click"]
})], IconButton);
export default IconButton;