import axios from "axios";
import { Vue } from "vue-class-component";
export default class PdpDisplay extends Vue {
  constructor() {
    super(...arguments);
    this.pdpContent = "";
  }
  mounted() {
    this.getpdpContent();
  }
  async getpdpContent() {
    const result = await axios.get("/pdp/getPdp", {
      params: {
        dataType: "json"
      }
    });
    this.pdpContent = result.data;
    await this.$nextTick();
    this.$emit("pdpLoaded");
  }
}
/*
    To set some specific style in the content of the pdp in the DataBase use :
     - classe="title"
     - classe="subtitle"
     - classe="text"

    those classes are defined in a non-scoped Stylesheet at the end of this document.
    Non-scoped, because the classes contained in the DB could not be interpreted by a scoped style
     */