import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import axios from "axios";
import GridLayout from "@/components/layout/GridLayout.vue";
import GetStartedCard from "@/components/getStartedBlock/GetStartedCard.vue";
import { Prop } from "vue-property-decorator";
let GetStartedBlock = class GetStartedBlock extends Vue {
  constructor() {
    super(...arguments);
    this.mutableShowCreateSpeachCard = this.showCreateSpeachCard;
    this.mutableShowCreateUserCard = this.showCreateUserCard;
    this.mutableShowCreateWeetCard = this.showCreateWeetCard;
  }
  get blockContainsAtLeastOneCard() {
    return this.mutableShowCreateSpeachCard || this.mutableShowCreateUserCard || this.numberOfSpeachesAwaitingValidation > 0 || this.numberOfSpeachesToValidate > 0 || this.numberOfUnreadNotification > 0;
  }
  removeCreateSpeachCard() {
    this.removeGetStartedCard("SPEACH").then(() => {
      this.mutableShowCreateSpeachCard = false;
    });
  }
  removeCreateWeetCard() {
    this.removeGetStartedCard("WEET").then(() => {
      this.mutableShowCreateWeetCard = false;
    });
  }
  removeCreateUserCard() {
    this.removeGetStartedCard("USER").then(() => {
      this.mutableShowCreateUserCard = false;
    });
  }
  removeGetStartedCard(type) {
    return axios.get("/user.usercontroller/removegetstarted", {
      params: {
        type: type
      }
    }).then(() => {
      //
      // success(this.$t("get.started.remove.succes"));
    });
  }
  navigate(url) {
    window.location.assign(url);
  }
};
__decorate([Prop({
  default: true
})], GetStartedBlock.prototype, "showCreateSpeachCard", void 0);
__decorate([Prop({
  default: true
})], GetStartedBlock.prototype, "showCreateWeetCard", void 0);
__decorate([Prop({
  default: true
})], GetStartedBlock.prototype, "showCreateUserCard", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfSpeachesAwaitingValidation", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfSpeachesToValidate", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfUnreadNotification", void 0);
GetStartedBlock = __decorate([Options({
  components: {
    GridLayout,
    GetStartedCard
  }
})], GetStartedBlock);
export default GetStartedBlock;