import { __decorate } from "tslib";
import { Prop, Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { Metric, Period } from "@/models/dashboard.model";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import AdvancedLineChart from "@/components/dashboard/charts/AdvancedLineChart.vue";
import DashboardTable from "@/components/dashboard/charts/DashboardTable.vue";
import { getSpeachMakerNote, getSpeachRating, getTimeSerie, getTopSpeachData, getTopSpeachMakerData } from "@/util/dashboardUtils";
let DashboardSocialTab = class DashboardSocialTab extends Vue {
  constructor() {
    super(...arguments);
    this.topSpeachByRating = [];
    this.topSpeachmakerByRating = [];
    this.grades = [];
    this.comments = [];
    this.socialInteractVisible = false;
    this.topSpeachRatingVisible = false;
    this.topSpeachMakerRatingVisible = false;
    this.socialInteractLoading = false;
    this.topSpeachRatingLoading = false;
    this.topSpeachMakerRatingLoading = false;
  }
  async visibilityChangedSocialInteract(value) {
    this.socialInteractVisible = value;
    if (value && this.grades.length === 0) {
      this.socialInteractLoading = true;
      getTimeSerie(Metric.GRADE, this.selectedPeriod, false).then(res => this.grades = res).finally(() => this.socialInteractLoading = false);
    }
    if (value && this.comments.length === 0) {
      this.socialInteractLoading = true;
      getTimeSerie(Metric.COMMENT, this.selectedPeriod, false).then(res => this.comments = res).finally(() => this.socialInteractLoading = false);
    }
  }
  async visibilityChangedTopSpeachRating(value) {
    this.topSpeachRatingVisible = value;
    if (value && this.topSpeachByRating.length === 0) {
      this.topSpeachRatingLoading = true;
      getSpeachRating(this.selectedPeriod).then(res => this.topSpeachByRating = getTopSpeachData(res)).finally(() => this.topSpeachRatingLoading = false);
    }
  }
  async visibilityChangedTopSpeachMakerRating(value) {
    this.topSpeachMakerRatingVisible = value;
    if (value && this.topSpeachmakerByRating.length === 0) {
      this.topSpeachMakerRatingLoading = true;
      getSpeachMakerNote(this.selectedPeriod).then(res => this.topSpeachmakerByRating = getTopSpeachMakerData(res)).finally(() => this.topSpeachMakerRatingLoading = false);
    }
  }
  get topSpeachmakerByRatingParams() {
    return [{
      column: this.$t("admin.dashboard.table.headers.speachmakers"),
      key: "speachmaker",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.nbScores"),
      key: "nbNote",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.averageScore"),
      key: "note",
      width: "20%"
    }];
  }
  get topSpeachByRatingParams() {
    return [{
      column: this.$t("admin.dashboard.table.headers.speachTitle"),
      key: "speachTitle",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.firstPublishDate"),
      key: "publishDateFormatted",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.averageScore"),
      key: "note",
      width: "20%"
    }];
  }
  get socialInteract() {
    return [{
      legend: this.$t("admin.dashboard.social.interact.legend.score"),
      tooltipLegend: this.$t("admin.dashboard.social.interact.legend.tooltip.score"),
      rgbaColor: "rgba(147, 147, 241,1)",
      data: this.grades
    }, {
      legend: this.$t("admin.dashboard.social.interact.legend.comments"),
      tooltipLegend: this.$t("admin.dashboard.social.interact.legend.tooltip.comments"),
      rgbaColor: "rgba(95, 95, 255,1)",
      data: this.comments
    }];
  }
  changeSelectedPeriod() {
    this.resetAllReports();
  }
  async resetAllReports() {
    this.topSpeachByRating = [];
    this.topSpeachmakerByRating = [];
    this.grades = [];
    this.comments = [];
    this.visibilityChangedSocialInteract(this.socialInteractVisible);
    this.visibilityChangedTopSpeachRating(this.topSpeachRatingVisible);
    this.visibilityChangedTopSpeachMakerRating(this.topSpeachMakerRatingVisible);
  }
};
__decorate([Prop({
  default: Period.THREE_MONTHS
})], DashboardSocialTab.prototype, "selectedPeriod", void 0);
__decorate([Watch("selectedPeriod")], DashboardSocialTab.prototype, "changeSelectedPeriod", null);
DashboardSocialTab = __decorate([Options({
  components: {
    Tile,
    DashboardTable,
    AdvancedLineChart
  }
})], DashboardSocialTab);
export default DashboardSocialTab;