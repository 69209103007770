import { __decorate } from "tslib";
import { Prop } from "vue-property-decorator";
//@ts-ignore
import { Carousel, Slide } from 'vue3-carousel';
// Import Swiper Vue.js components
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import StandardSpeachCard from "@/components/card/StandardSpeachCard.vue";
import Icon from "@/components/icon/Icon.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import AddCard from "@/components/card/AddCard.vue";
import BaseSpeachCard from "@/components/card/BaseSpeachCard.vue";
import { DateFilterEnum } from "@/models/DateFilterEnum";
let SpeachSlider = class SpeachSlider extends Vue {
  constructor() {
    super(...arguments);
    this.currentPage = 1;
    this.listOfSpeach = [];
    this.overflow = false;
    this.speachDateToDisplay = DateFilterEnum.PUBLICATION_DATE;
  }
  async visibilityChanged(value) {
    // eslint-disable-next-line no-constant-condition
    if (value && this.listOfSpeach.length === 0) {
      const result = await this.loadPage();
      if (result) {
        this.listOfSpeach = result.result;
        if (result.total > 4) {
          this.overflow = true;
        }
      }
    }
  }
  getClass(id) {
    return "cardAnime" + (id + 1) % 12;
  }
  get getBreakpoint() {
    return {
      0: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      607: {
        itemsToShow: 2,
        snapAlign: 'start'
      },
      897: {
        itemsToShow: 3,
        snapAlign: 'start'
      },
      1188: {
        itemsToShow: 4,
        snapAlign: 'start'
      }
    };
  }
  next() {
    //@ts-ignore
    this.$refs.slider.next();
  }
  prev() {
    //@ts-ignore
    this.$refs.slider.prev();
  }
  goToFullPage() {
    if (!this.outframe) {
      window.location.href = this.urlFullPage;
    } else {
      open(this.urlFullPage, 'blank');
    }
  }
  async loadPage() {
    const result = await axios.get(this.url, {
      params: {
        page: this.currentPage
      }
    });
    return result.data;
  }
};
__decorate([Prop({
  required: true
})], SpeachSlider.prototype, "url", void 0);
__decorate([Prop({
  default: ""
})], SpeachSlider.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], SpeachSlider.prototype, "urlFullPage", void 0);
__decorate([Prop({
  default: false
})], SpeachSlider.prototype, "outframe", void 0);
__decorate([Prop({
  required: true
})], SpeachSlider.prototype, "commentsEnabled", void 0);
SpeachSlider = __decorate([Options({
  components: {
    BaseSpeachCard,
    AddCard,
    IconButton,
    Icon,
    StandardSpeachCard,
    Carousel,
    Slide
  }
})], SpeachSlider);
export default SpeachSlider;