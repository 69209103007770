import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aac4cc54"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "flex-direction": "row-reverse",
    "justify-content": "flex-end"
  }
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  key: 0,
  class: "_courAuteurDelta",
  style: {
    "font-size": "14px"
  }
};
const _hoisted_5 = {
  class: "courAuteurDeltaLabel marginl5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_author_icon_group_picto = _resolveComponent("author-icon-group-picto");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsInfosReversed, authorInfo => {
    return _openBlock(), _createElementBlock("div", {
      key: authorInfo.title,
      title: authorInfo.title
    }, [_createVNode(_component_author_icon_group_picto, {
      user: authorInfo,
      size: _ctx.size
    }, null, 8, ["user", "size"])], 8, _hoisted_3);
  }), 128))]), _ctx.numberOfCoEditorsNotDisplayed > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, " + " + _toDisplayString(_ctx.numberOfCoEditorsNotDisplayed), 1)])) : _createCommentVNode("", true)]);
}