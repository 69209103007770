import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
let wToolTip = class wToolTip extends Vue {};
__decorate([Prop({
  default: 'top-start'
})], wToolTip.prototype, "placement", void 0);
__decorate([Prop({
  default: ''
})], wToolTip.prototype, "title", void 0);
__decorate([Prop({
  default: 200
})], wToolTip.prototype, "width", void 0);
__decorate([Prop({
  required: true
})], wToolTip.prototype, "text", void 0);
wToolTip = __decorate([Options({})], wToolTip);
export default wToolTip;