import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.realValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.realValue = $event),
    disabled: !_ctx.enabled,
    size: _ctx.size,
    loading: _ctx.loading,
    placeholder: _ctx.placeholder,
    onBlur: _ctx.onBlur,
    "data-test": _ctx.dataTest,
    onChange: _ctx.onChangeValue
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item[this.valueKey] + 'select',
        label: item[this.labelKey],
        value: item[this.valueKey],
        "data-test": _ctx.dataTest + '-' + item[this.valueKey]
      }, null, 8, ["label", "value", "data-test"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "disabled", "size", "loading", "placeholder", "onBlur", "data-test", "onChange"]);
}