import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4579ab9a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "userDetails"
};
const _hoisted_2 = {
  class: "scrollableUserDetails"
};
const _hoisted_3 = {
  class: "row margin24"
};
const _hoisted_4 = {
  class: "row margin24"
};
const _hoisted_5 = {
  class: "row"
};
const _hoisted_6 = {
  class: "column"
};
const _hoisted_7 = {
  class: "subTitle"
};
const _hoisted_8 = {
  class: "row"
};
const _hoisted_9 = {
  class: "column"
};
const _hoisted_10 = {
  class: "subTitle"
};
const _hoisted_11 = {
  class: "column"
};
const _hoisted_12 = {
  class: "subTitle"
};
const _hoisted_13 = {
  class: "row"
};
const _hoisted_14 = {
  class: "column"
};
const _hoisted_15 = {
  class: "subTitle"
};
const _hoisted_16 = {
  class: "column"
};
const _hoisted_17 = {
  class: "subTitle"
};
const _hoisted_18 = {
  class: "row"
};
const _hoisted_19 = {
  class: "column"
};
const _hoisted_20 = {
  class: "subTitle"
};
const _hoisted_21 = {
  class: "row"
};
const _hoisted_22 = {
  class: "column width420"
};
const _hoisted_23 = {
  class: "column"
};
const _hoisted_24 = {
  key: 1
};
const _hoisted_25 = {
  class: "password-field-block"
};
const _hoisted_26 = {
  class: "row"
};
const _hoisted_27 = {
  class: "dialog-footer"
};
const _hoisted_28 = {
  class: "popupRow"
};
const _hoisted_29 = {
  class: "messagePopup"
};
const _hoisted_30 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_user_cell = _resolveComponent("user-cell");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_icon = _resolveComponent("icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_w_checkbox_group = _resolveComponent("w-checkbox-group");
  const _component_w_input = _resolveComponent("w-input");
  const _component_w_select_advanced = _resolveComponent("w-select-advanced");
  const _component_w_tag = _resolveComponent("w-tag");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_user_cell, {
      user: _ctx.user,
      "search-term": "",
      "second-line-content": _ctx.lastActiveLabel,
      size: "large"
    }, null, 8, ["user", "second-line-content"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_standard_button, {
      class: "column standardButton",
      label: _ctx.$t('admin.dashboard.users.sidebar.viewProfile'),
      expanded: true,
      onClick: _ctx.goToUser
    }, null, 8, ["label", "onClick"]), _createVNode(_component_standard_button, {
      class: "column standardButton",
      label: _ctx.$t('admin.dashboard.users.sidebar.analytics'),
      expanded: true,
      outlined: true,
      onClick: _ctx.goToUserAnalytics
    }, null, 8, ["label", "onClick"]), _createVNode(_component_el_dropdown, {
      trigger: "click",
      placement: "bottom-end"
    }, {
      dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
          onClick: _cache[0] || (_cache[0] = $event => _ctx.resetPasswordPopup = true)
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "lock-reset",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.passwordReset.label')), 1)]),
          _: 1
        }), _createVNode(_component_el_dropdown_item, {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.deactivateUserPopup = true)
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "delete-outline",
            class: "iconMenu"
          }), _createTextVNode("  " + _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.deactivation.label')), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_standard_button, {
        class: "standardButton",
        label: "...",
        outlined: true,
        "very-small-margins": true
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "dots-horizontal",
          size: "small"
        })]),
        _: 1
      })]),
      _: 1
    })]), _createVNode(_component_el_divider, {
      class: "hrDivider"
    }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('admin.dashboard.users.table.roles')), 1), _createVNode(_component_w_checkbox_group, {
      items: _ctx.possibleRoles,
      "already-checked-items": _ctx.userRoles,
      "label-key": "name",
      "value-key": "key",
      onCheckedOneItem: _ctx.onChangeOneRole
    }, null, 8, ["items", "already-checked-items", "onCheckedOneItem"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.firstName')), 1), _createVNode(_component_w_input, {
      value: _ctx.user.firstName,
      "no-prefix": true,
      onChangeInput: _cache[2] || (_cache[2] = $event => _ctx.onChangeFirstName($event)),
      maxlength: "30"
    }, null, 8, ["value"])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.lastName')), 1), _createVNode(_component_w_input, {
      value: _ctx.user.lastName,
      "no-prefix": true,
      onChangeInput: _cache[3] || (_cache[3] = $event => _ctx.onChangeLastName($event)),
      maxlength: "30"
    }, null, 8, ["value"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.email')), 1), _createVNode(_component_w_input, {
      value: _ctx.user.email,
      "no-prefix": true,
      onChangeInput: _cache[4] || (_cache[4] = $event => _ctx.onChangeEmail($event)),
      maxlength: "230"
    }, null, 8, ["value"])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCustomFields, userCustomFieldValue => {
      return _openBlock(), _createElementBlock("div", {
        key: userCustomFieldValue.customField.name,
        class: "row"
      }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getTranslatedCustomFieldLabel(userCustomFieldValue.customField)), 1), _createVNode(_component_w_input, {
        value: userCustomFieldValue.value,
        "no-prefix": true,
        onChangeInput: $event => _ctx.onChangeUserCustomFieldValue(userCustomFieldValue, $event)
      }, null, 8, ["value", "onChangeInput"])])]);
    }), 128)), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('admin.dashboard.users.table.groups')), 1), _createVNode(_component_w_select_advanced, {
      options: _ctx.displayableGroups,
      class: "selectWithoutTags",
      onChange: _ctx.changeSelectedGroups,
      value: _ctx.selectedGroups,
      "value-key": "id",
      "label-key": "name",
      multiple: true,
      filterable: true,
      placeholder: _ctx.$t('admin.dashboard.users.sidebar.selectGroups'),
      "class-click-filter": _ctx.classClickFilter
    }, null, 8, ["options", "onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.groups.filter(g => !g.system), group => {
      return _openBlock(), _createBlock(_component_w_tag, {
        textTag: group.name,
        key: group.id,
        round: "",
        onRemoveTag: $event => _ctx.deleteOneGroup(group)
      }, null, 8, ["textTag", "onRemoveTag"]);
    }), 128))])])])])]),
    _: 1
  }), _createVNode(_component_w_dialog, {
    visible: _ctx.resetPasswordPopup,
    title: _ctx.$t('admin.dashboard.users.sidebar.passwordReset.title'),
    "close-on-outside-click": false,
    onOnDialogClose: _cache[8] || (_cache[8] = $event => _ctx.resetPasswordPopup = false)
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_27, [_createVNode(_component_standard_button, {
      label: _ctx.$t('admin.dashboard.users.sidebar.passwordReset.close'),
      onClick: _cache[7] || (_cache[7] = $event => _ctx.resetPasswordPopup = false)
    }, null, 8, ["label"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createVNode(_component_user_cell, {
      user: _ctx.user,
      "search-term": "",
      "second-line-content": _ctx.user.email,
      width: "300px"
    }, null, 8, ["user", "second-line-content"])]), _createElementVNode("div", _hoisted_23, [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.passwordReset.newPassword').toString()) + ": ", 1), !_ctx.userPasswordIsEditable ? (_openBlock(), _createBlock(_component_standard_button, {
      key: 0,
      label: _ctx.$t('admin.dashboard.users.sidebar.passwordReset.generate'),
      onClick: _ctx.generatePassword
    }, null, 8, ["label", "onClick"])) : (_openBlock(), _createElementBlock("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createVNode(_component_w_input, {
      "no-prefix": true,
      type: "password",
      size: "small",
      value: _ctx.passwordField,
      onInput: _cache[5] || (_cache[5] = value => _ctx.passwordField = value)
    }, null, 8, ["value"]), _createVNode(_component_standard_button, {
      label: _ctx.$t('admin.dashboard.users.sidebar.passwordReset.submitPasswordBtn').toString(),
      disabled: !_ctx.passwordField.length,
      onClick: _ctx.updateUserPassword
    }, null, 8, ["label", "disabled", "onClick"])]), _createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.passwordReset.optionConnector').toString()) + " ", 1), _createElementVNode("a", {
      onClick: _cache[6] || (_cache[6] =
      //@ts-ignore
      (...args) => _ctx.generatePassword && _ctx.generatePassword(...args))
    }, [_createElementVNode("u", null, _toDisplayString(_ctx.$t('admin.dashboard.users.sidebar.passwordReset.generateRandomPassword').toString()), 1)])])]))])]), _createElementVNode("div", _hoisted_26, [_createVNode(_component_w_checkbox_group, {
      items: _ctx.passwordOptions,
      "label-key": "name",
      "value-key": "key",
      onCheckedOneItem: _ctx.onChangePasswordOption
    }, null, 8, ["items", "onCheckedOneItem"])])]),
    _: 1
  }, 8, ["visible", "title"]), _createVNode(_component_w_dialog, {
    visible: _ctx.deactivateUserPopup,
    onOnDialogClose: _cache[10] || (_cache[10] = $event => _ctx.deactivateUserPopup = false)
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_30, [_createVNode(_component_standard_button, {
      outlined: true,
      label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.cancel'),
      onClick: _cache[9] || (_cache[9] = $event => _ctx.deactivateUserPopup = false)
    }, null, 8, ["label"]), _createVNode(_component_standard_button, {
      label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.ok'),
      onClick: _ctx.deactivateUser
    }, null, 8, ["label", "onClick"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_28, [_createVNode(_component_icon, {
      class: "iconPopup",
      icon: "information-outline",
      size: "large"
    }), _createElementVNode("div", _hoisted_29, _toDisplayString(
    // noinspection TypeScriptValidateTypes: We can totally add parameters to the $t method, for parameterized translations (and it works)
    _ctx.$tc('admin.dashboard.users.sidebar.deactivation.message', 2)), 1)])]),
    _: 1
  }, 8, ["visible"])]);
}