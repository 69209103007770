import { __decorate } from "tslib";
import { Prop, Watch } from 'vue-property-decorator';
import { Vue } from "vue-class-component";
export default class WCheckboxGroup extends Vue {
  constructor() {
    super(...arguments);
    this.checkedItems = [];
  }
  mounted() {
    this.checkedItems = this.alreadyCheckedItems;
  }
  onChangeOneItem(changed, label, value) {
    this.$emit('checkedOneItem', changed, label, value);
  }
  onChangeItems() {
    this.$emit('checkedItems', this.checkedItems);
  }
  onChangePropValue() {
    this.checkedItems = this.alreadyCheckedItems;
  }
}
__decorate([Prop({
  default: []
})], WCheckboxGroup.prototype, "alreadyCheckedItems", void 0);
__decorate([Prop({
  default: []
})], WCheckboxGroup.prototype, "items", void 0);
__decorate([Prop({
  default: "id"
})], WCheckboxGroup.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "name"
})], WCheckboxGroup.prototype, "labelKey", void 0);
__decorate([Watch('checkedItems')], WCheckboxGroup.prototype, "onChangeItems", null);
__decorate([Watch('alreadyCheckedItems')], WCheckboxGroup.prototype, "onChangePropValue", null);