import { __decorate } from "tslib";
import { Prop, Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { Metric, Period } from "@/models/dashboard.model";
import DashboardUsersTiles from "@/components/dashboard/tiles/DashboardUsersTiles.vue";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import AdvancedLineChart from "@/components/dashboard/charts/AdvancedLineChart.vue";
import DashboardTable from "@/components/dashboard/charts/DashboardTable.vue";
import { getSpeachMakerWatch, getTimeSerie, getTopSpeachMakerData } from "@/util/dashboardUtils";
let DashboardUsersTab = class DashboardUsersTab extends Vue {
  constructor() {
    super(...arguments);
    this.topSpeachmakerByWatch = [];
    this.uniqueActivities = [];
    this.usersActivityVisible = false;
    this.topSpeachMakerWatchVisible = false;
    this.usersActivityLoading = false;
    this.topSpeachMakerWatchLoading = false;
  }
  async visibilityChangedUsersActivity(value) {
    this.usersActivityVisible = value;
    if (value && this.uniqueActivities.length === 0) {
      this.usersActivityLoading = true;
      getTimeSerie(Metric.UNIQUE_ACTIVITY, this.selectedPeriod, false).then(res => this.uniqueActivities = res).finally(() => this.usersActivityLoading = false);
    }
  }
  async visibilityChangedTopSpeachMakerWatch(value) {
    this.topSpeachMakerWatchVisible = value;
    if (value && this.topSpeachmakerByWatch.length === 0) {
      this.topSpeachMakerWatchLoading = true;
      getSpeachMakerWatch(this.selectedPeriod).then(res => this.topSpeachmakerByWatch = getTopSpeachMakerData(res)).finally(() => this.topSpeachMakerWatchLoading = false);
    }
  }
  get topSpeachmakerByWatchParams() {
    return [{
      column: this.$t("admin.dashboard.table.headers.speachmakers"),
      key: "speachmaker",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.nbSpeachs"),
      key: "nbSpeachs",
      width: "40%"
    }, {
      column: this.$t("admin.dashboard.table.headers.read"),
      key: "nbWatch",
      width: "20%"
    }];
  }
  get usersActivity() {
    return [{
      legend: this.$t("admin.dashboard.users.activity.legend.unique.connections"),
      tooltipLegend: this.$t("admin.dashboard.users.activity.legend.tooltip.unique.connections"),
      rgbaColor: "rgb(147, 147, 241)",
      data: this.uniqueActivities
    }];
  }
  changeSelectedPeriod() {
    this.resetAllReports();
  }
  async resetAllReports() {
    this.topSpeachmakerByWatch = [];
    this.uniqueActivities = [];
    this.visibilityChangedTopSpeachMakerWatch(this.topSpeachMakerWatchVisible);
    this.visibilityChangedUsersActivity(this.usersActivityVisible);
  }
};
__decorate([Prop({
  default: Period.THREE_MONTHS
})], DashboardUsersTab.prototype, "selectedPeriod", void 0);
__decorate([Watch("selectedPeriod")], DashboardUsersTab.prototype, "changeSelectedPeriod", null);
DashboardUsersTab = __decorate([Options({
  components: {
    DashboardTable,
    AdvancedLineChart,
    Tile,
    DashboardUsersTiles
  }
})], DashboardUsersTab);
export default DashboardUsersTab;